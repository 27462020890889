import Routes from './config/Routes'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react'

function App() {
  useEffect(() => {
    if(JSON.parse(localStorage.getItem('DARK_MODE')) === true ) {
      document.body.classList.add('dark-mode')
    }
  }, [])
  return (
    <>
      <ToastContainer />
      <Routes />
    </>
  );
}

export default App;
