import React, { useEffect } from 'react'
import FeatureItem from './FeatureItem'
import './features-section.scss'
import { data1, data2, data3, data4, data5 } from '../../../pages/Landing/Data'
import AOS from 'aos'
import 'aos/dist/aos.css'

const FeaturesSection = () => {
  useEffect(() => {
    AOS.init()
  }, [])
  return (
    <div className='container' id='features'>
      <h2 className='text-center mb-5' data-aos='fade-up'>
        Our Features
      </h2>
      <div className='row d-flex justify-content-center'>
        <FeatureItem {...data1}>
          <data1.icon />
        </FeatureItem>
        <FeatureItem {...data2}>
          <data2.icon />
        </FeatureItem>
        <FeatureItem {...data3}>
          <data3.icon />
        </FeatureItem>
        <FeatureItem {...data4}>
          <data4.icon />
        </FeatureItem>
        <FeatureItem {...data5}>
          <data5.icon />
        </FeatureItem>
      </div>
    </div>
  )
}

export default FeaturesSection
