import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ILNoGroup } from '../../../assets'
import { LazyImage } from '../../../components'
import ContactCard from '../../../components/ContactCard'
import {
  fetchDocumentMessage,
  fetchImageMessage,
} from '../../../state/message/messageActions'
import {
  fetchDetailRoom,
  fetchMemberRoom,
} from '../../../state/room/roomActions'

function Dashboard() {
  const { myRoom } = useSelector((state) => state.room)
  const [isLoading, setIsLoading] = useState(false)
  const [idRoom, setIdRoom] = useState(null)
  const dispatch = useDispatch()
  const handleDetail = async (id) => {
    setIdRoom(id)
    setIsLoading(true)
    await dispatch(fetchMemberRoom(id))
    await dispatch(fetchDetailRoom(id))
    await dispatch(fetchImageMessage(id))
    await dispatch(fetchDocumentMessage(id))
    setIsLoading(false)
  }
  return (
    <div className='mt-4'>
      {myRoom.length === 0 ? (
        <>
          <h5 className='text-center mt-5'>Chat Empty</h5>
          <LazyImage
            src={ILNoGroup}
            isSvg
            alt='no group'
            className='img-fluid my-4'
          />
        </>
      ) : (
        myRoom.map((room, id) => (
          <ContactCard
            room={room}
            key={id}
            isLoading={isLoading}
            targetId={idRoom}
            showDetail={handleDetail}
          />
        ))
      )}
    </div>
  )
}

export default Dashboard
