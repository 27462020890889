const {
  GET_MY_ROOM,
  GET_DETAIL_ROOM,
  CLEAR_DETAIL_ROOM,
  GET_MEMBER_IN_ROOM,
  GET_GROUP_ROOM,
  GET_PRIVATE_ROOM,
  CLEAR_ROOM_STATE,
  GET_MY_ROOM_STATIC,
} = require('../actionTypes')

const initialState = {
  myRoom: [],
  myRoomStatic: [],
  privateRoom: [],
  memberRoom: [],
  groupRoom: [],
  detailRoom: {},
}

const roomReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_ROOM:
      return {
        ...state,
        myRoom: action.payload,
        privateRoom: action.payload.filter((item) => item.type === 1),
        groupRoom: action.payload.filter((item) => item.type === 2),
      }
    case GET_MY_ROOM_STATIC:
      return {
        ...state,
        myRoomStatic: action.payload,
      }
    case GET_DETAIL_ROOM:
      return {
        ...state,
        detailRoom: action.payload,
      }
    case GET_MEMBER_IN_ROOM:
      return {
        ...state,
        memberRoom: action.payload,
      }
    case GET_GROUP_ROOM:
      return {
        ...state,
        groupRoom: action.payload,
      }
    case GET_PRIVATE_ROOM:
      return {
        ...state,
        privateRoom: action.payload,
      }
    case CLEAR_DETAIL_ROOM:
      return {
        ...state,
        detailRoom: {},
      }
    case CLEAR_ROOM_STATE:
      return {
        myRoom: [],
        myRoomStatic: [],
        privateRoom: [],
        memberRoom: [],
        groupRoom: [],
        detailRoom: {},
      }
    default:
      return state
  }
}

export default roomReducers
