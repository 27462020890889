import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { fetchActivateAccount } from '../../../state/auth/authActions'
import { toastError, toastSuccess } from '../../../utils/toastUtils'

function ActivateAccount() {
  const history = useHistory()
  const dispatch = useDispatch()
  const query = new URLSearchParams(useLocation().search)
  const token = query.get('token')
  const isDarkMode = useSelector((state) => state.global.darkMode)
  useEffect(() => {
    if (!token) {
      history.push('/auth/signin')
      toastError('Token required')
    } else {
      const sendRequestActivate = async () => {
        try {
          await dispatch(fetchActivateAccount({ token }))
          toastSuccess('Account has been successfully activated')
          history.push('/auth/signin')
        } catch (err) {
          toastError(err.response.data.message)
          history.push('/auth/signin')
        }
      }
      sendRequestActivate()
    }
  }, [dispatch, history, token])
  return (
    <>
      <div className='d-flex align-items-center justify-content-center min-vh-100'>
        <div
          className={`spinner-border ${
            isDarkMode ? 'text-light' : 'text-dark'
          }`}
          role='status'
        >
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
    </>
  )
}

export default ActivateAccount
