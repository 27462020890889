import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ICBack } from '../../../assets'
import { Avatar } from '../../../components'
import { FaPen } from 'react-icons/fa'
import './setting.scss'
import { ModalUpdateProfile } from '../../../domain/Setting'
import { useSelector } from 'react-redux'

function Setting() {
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)
  const handleClose = () => setShowModal(false)
  const handleShow = () => setShowModal(true)
  const detailUser = useSelector((state) => state.user.detailUser)

  return (
    <>
      <div className='mt-4 mb-4 position-relative'>
        <span onClick={() => history.push('/home')} className='cursor-pointer'>
          <img className='back-icon' src={ICBack} alt='back' />
        </span>
        <h5 className='username-title'>
          {detailUser.username ? `@${detailUser.username}` : 'No username yet'}
        </h5>
      </div>
      <div className='mb-5 text-center'>
        <Avatar rounded='sm' src={detailUser.image} hasShadow />
        <h5 className='name-title'>
          {detailUser.name}
          <span className='ml-1 cursor-pointer' onClick={handleShow}>
            <FaPen size='14px' />
          </span>
        </h5>
      </div>
      <div className='setting-item'>
        <p className='title-account'>Account</p>
        <p className='sub-title'>
          {detailUser.phoneNumber
            ? detailUser.phoneNumber
            : 'No phone number yet'}
        </p>
        <p className='desc-title cursor-pointer' onClick={handleShow}>
          Tap to change phone number
        </p>
      </div>
      <div className='setting-item pt-3'>
        <p className='sub-title'>
          {detailUser.username ? `@${detailUser.username}` : 'No username yet'}
        </p>
        <p className='desc-title'>Username</p>
      </div>
      <div className='setting-item pt-3'>
        <p className='sub-title'>
          {detailUser.bio ? detailUser.bio : 'The bio is not set yet'}
        </p>
        <p className='desc-title'>Bio</p>
      </div>
      {showModal && (
        <ModalUpdateProfile show={showModal} handleClose={handleClose} />
      )}
    </>
  )
}

export default Setting
