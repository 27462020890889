import React from 'react'
import { Router, Route } from 'react-router-dom'
import { Auth } from '../pages'
import ActivateAccount from '../pages/Auth/ActivateAccount'
import { createBrowserHistory } from 'history'
import Landing from '../pages/Landing'
import Home from '../pages/Home'
import Awikwok from '../pages/Awikwok'
import Testing from '../pages/Testing'
export const history = createBrowserHistory()

function Routes() {
  return (
    <>
      <Router history={history}>
        <Route path='/' exact>
          <Landing />
        </Route>
        <Route path='/auth'>
          <Auth />
        </Route>
        <Route path='/home'>
          <Home />
        </Route>
        <Route path='/awikwok'>
          <Awikwok />
        </Route>
        <Route path='/tes'>
          <Testing />
        </Route>
        <Route path='/auth/activate-account'>
          <ActivateAccount />
        </Route>
      </Router>
    </>
  )
}

export default Routes
