import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Story from './Story'
import ModalStory from './ModalStory'
import ModalAddStory from './ModalAddStory'
import './Story/story.scss'
import ModalListViewers from './ModalListViewers'
import {
  fetchDetailStory,
  fetchViewersStory,
} from '../../state/story/storyActions'

function Stories() {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [isPause, setIsPause] = useState(false)
  const [isLoadingLocal, setIsLoadingLocal] = useState(false)
  const [targetId, setTargetId] = useState(null)
  const handleClose = () => setShow(false)
  const handleShow = async (id) => {
    setTargetId(id)
    setIsLoadingLocal(true)
    const response = await dispatch(fetchDetailStory(id))
    await dispatch(fetchViewersStory(response.data.data.id))
    setShow(true)
    setIsLoadingLocal(false)
  }
  const [showViewers, setShowViewers] = useState(false)
  const handleShowViewers = () => {
    setIsPause(true)
    setShowViewers(true)
  }
  const handleCloseViewers = () => {
    setIsPause(false)
    setShowViewers(false)
  }

  const friendStories = useSelector((state) => state.story.friendStories)
  const checkMyStory = useSelector((state) => state.story.myStory)
  const detailUser = useSelector((state) => state.user.detailUser)
  const [showModalAddStory, setShowModalAddStory] = useState(false)
  const handleCloseAdd = () => setShowModalAddStory(false)
  const handleShowAdd = () => setShowModalAddStory(true)
  const myStory = {
    idUser: detailUser.id || null,
    userName: detailUser.name || 'user',
    imageUser: detailUser.image || '',
  }

  return (
    <div className='message-by d-flex overflow-auto py-2 align-items-center'>
      <div className='d-flex flex-column align-items-center justify-content-between mr-3'>
        <button
          className='btn btn-sm btn-primary shadow mb-3'
          onClick={handleShowAdd}
        >
          +
        </button>
      </div>
      {checkMyStory.data && (
        <Story
          myStory
          dataStory={myStory}
          isLoading={isLoadingLocal}
          targetId={targetId}
          showStory={handleShow}
        />
      )}
      {
        <>
          {friendStories.map((story, i) => (
            <Story
              dataStory={story}
              key={i}
              targetId={targetId}
              isLoading={isLoadingLocal}
              showStory={handleShow}
            />
          ))}
        </>
      }
      {show && (
        <ModalStory
          show={show}
          handleClose={handleClose}
          handleShowViewers={handleShowViewers}
          isPause={isPause}
          setIsPause={setIsPause}
        />
      )}

      <ModalAddStory show={showModalAddStory} handleClose={handleCloseAdd} />
      {showViewers && (
        <ModalListViewers show={showViewers} handleClose={handleCloseViewers} />
      )}
    </div>
  )
}

export default Stories
