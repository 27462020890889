import React from 'react'
import './join-section.scss'
import img1 from '../../../assets/images/illustration/Mobile login-pana.svg'
import img2 from '../../../assets/images/illustration/Email campaign-pana.svg'
import img3 from '../../../assets/images/illustration/Launching-pana.svg'
import { Button, LazyImage } from '../../../components'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'

const JoinSection = () => {
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <div id='join'>
      <div className='container'>
        <h2 className='join-title' data-aos='fade-up'>
          Join In Three Easy Steps
        </h2>
        <div className='row d-flex justify-content-center'>
          <div className='col-lg-4 col-sm-6 col-12 mb-4' data-aos='fade-up'>
            <div className='card card-join'>
              <LazyImage src={img1} alt='join' className='img-join' isSvg />
              <div className='card-body'>
                <p className='join-item-title'>Sign Up</p>
                <p className='join-desc'>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. At,
                  vel?
                </p>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-sm-6 mb-4' data-aos='fade-up'>
            <div className='card card-join'>
              <LazyImage
                src={img2}
                alt='verify email'
                className='img-join'
                isSvg
              />
              <div className='card-body'>
                <p className='join-item-title'>Verify Email</p>
                <p className='join-desc'>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. At,
                  vel?
                </p>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-sm-6 mb-4' data-aos='fade-up'>
            <div className='card card-join'>
              <LazyImage
                src={img3}
                alt='start messaging'
                className='img-join'
                isSvg
              />
              <div className='card-body'>
                <p className='join-item-title'>Start Messaging</p>
                <p className='join-desc'>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. At,
                  vel?
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-center' data-aos='fade-up'>
          <Button
            isPrimary
            hasShadow
            type='link'
            className='btn join-btn'
            href='/auth/signin'
          >
            Join Now
          </Button>
        </div>
      </div>
    </div>
  )
}

export default JoinSection
