import {
  FaComments,
  FaPhoneAlt,
  FaShareAlt,
  FaMapMarkerAlt,
  FaUserPlus,
} from 'react-icons/fa'

export const data1 = {
  icon: FaComments,
  title: 'Messagging',
  description:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, quos? Officiis harum a',
}
export const data2 = {
  icon: FaPhoneAlt,
  title: 'Call & Video Call',
  description:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, quos? Officiis harum a',
}
export const data3 = {
  icon: FaShareAlt,
  title: 'Stories',
  description:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, quos? Officiis harum a',
}

export const data4 = {
  icon: FaMapMarkerAlt,
  title: 'Share Location',
  description:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, quos? Officiis harum a',
}
export const data5 = {
  icon: FaUserPlus,
  title: 'Find New Friend',
  description:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, quos? Officiis harum a',
}
