import axios from 'axios'
import { GET_STICKER } from '../actionTypes'

export const fetchStickerGiphy = () => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          'https://api.giphy.com/v1/stickers/packs/3138/stickers?api_key=CjX1GRX7x8ymfuZarVUAjTamK0naAY42&limit=30&offset=0',
        )
        .then((response) => {
          resolve(response)
          dispatch({ type: GET_STICKER, payload: response.data.data })
        })
        .catch((err) => {
          console.log(err)
          reject(err)
        })
    })
  }
}
