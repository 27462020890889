import MessageApi from '../../apis/messageApi'
import {
  CLEAR_MESSAGE_STATE,
  GET_DETAIL_MESSAGE,
  GET_DOCUMENT_MESSAGE,
  GET_IMAGE_MESSAGE,
} from '../actionTypes'

export const getDetailMessage = (data) => {
  return {
    type: GET_DETAIL_MESSAGE,
    payload: data,
  }
}

export const getImageMessage = (data) => {
  return {
    type: GET_IMAGE_MESSAGE,
    payload: data,
  }
}

export const getDocumentMessage = (data) => {
  return {
    type: GET_DOCUMENT_MESSAGE,
    payload: data,
  }
}

export const clearMessageState = () => {
  return {
    type: CLEAR_MESSAGE_STATE,
  }
}

export const fetchDetailMessage = (id, page) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      MessageApi.getDetailMessage(id, page)
        .then((response) => {
          resolve(response)
          dispatch(getDetailMessage(response.data.data))
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchImageMessage = (id, page) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      MessageApi.getImageMessage(id, page)
        .then((response) => {
          resolve(response)
          dispatch(getImageMessage(response.data.data))
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchDocumentMessage = (id, page) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      MessageApi.getDocumentMessage(id, page)
        .then((response) => {
          resolve(response)
          dispatch(getDocumentMessage(response.data.data))
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchMessageById = (id) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      MessageApi.getMessageById(id)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchNextMessage = (id, page) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      MessageApi.getDetailMessage(id, page)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchSendMessage = (data) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      MessageApi.sendMessage(data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchDeleteMessage = (id) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      MessageApi.deleteMessage(id)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
