import React, { useState } from 'react'
import { Avatar, Button, LazyImage, MapItem } from '../../../components'
import { ZoomImage } from '../../../components'
import './chat-card.scss'
import { FaEllipsisH, FaTimes, FaTrash } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import filterTime from '../../../utils/filterTime'

function ChatCard({ message, deleteMessage }) {
  // Type message
  // 1 text
  // 2 location
  // 3 send image
  // 4 send doc
  // 5 Send sticker
  // 6 create room
  // 7 join room
  // 8 friend request accepted
  // 9 leave room
  const [menu, setMenu] = useState(false)
  const [zoomImage, setZoomImage] = useState(false)
  const detailRoom = useSelector((state) => state.room.detailRoom)
  const detailUser = useSelector((state) => state.user.detailUser)
  const handleClose = () => setZoomImage(false)
  const handleShow = () => setZoomImage(true)
  const handleDeleteMessage = (id) => {
    deleteMessage(id)
    setMenu(false)
  }

  const chatCardClass = ['chat-card']
  if (detailUser.id === message.idUser) chatCardClass.push('sender')
  if (detailUser.id !== message.idUser) chatCardClass.push('receiver')

  if (message.type === 6 || message.type === 9 || message.type === 10) {
    return (
      <div className='d-flex justify-content-center my-2 mb-4'>
        <p className='text-center text-white px-3 py-2 rounded bg-secondary font-12 mb-0 d-inline-block'>
          {message.type === 6 && message.name} {message.message} -{' '}
          {filterTime(message.createdAt)}
        </p>
      </div>
    )
  }

  if (message.type === 7) {
    return (
      <div className='d-flex justify-content-center mt-2 mb-4'>
        <p className='text-center text-white px-3 py-2 rounded bg-secondary font-12 mb-0 d-inline-block'>
          {message.name} Join Group - {filterTime(message.createdAt)}
        </p>
      </div>
    )
  }

  // message map
  if (message.type === 2) {
    return (
      <div className={chatCardClass.join(' ')}>
        <div className='set'>
          <div className='contact-image'>
            <Avatar
              src={message.imageUser ? message.imageUser : ''}
              size='35px'
            />
          </div>
          <div className='message-text map shadow-sm'>
            <div className='d-flex justify-content-between'>
              <span className='chat-name'>
                {detailRoom.type === 2 ? message.name : ''}
              </span>
              {detailUser.id === message.idUser && (
                <span className='cursor-pointer' onClick={() => setMenu(!menu)}>
                  {!menu ? <FaEllipsisH /> : <FaTimes />}
                </span>
              )}
            </div>
            <span className='mb-0'>
              {menu ? (
                <div className='my-2'>
                  {!message.deletedAt && (
                    <Button
                      hasShadow
                      variant='danger'
                      onClick={() => handleDeleteMessage(message.id)}
                      isSmall
                      className='mr-2'
                    >
                      <FaTrash />
                    </Button>
                  )}
                </div>
              ) : message.deletedAt ? (
                <span className='deleted-message'>[ Deleted ]</span>
              ) : (
                <MapItem height={200} />
              )}
            </span>
            <small className='clock d-inline-block mt-1 ml-3'>
              {filterTime(message.createdAt)}
            </small>
          </div>
        </div>
      </div>
    )
  }
  // message image
  if (message.type === 3) {
    return (
      <div className={chatCardClass.join(' ')}>
        <div className='set'>
          <div className='contact-image'>
            <Avatar
              src={message.imageUser ? message.imageUser : ''}
              size='35px'
            />
          </div>
          <div className='message-text shadow-sm'>
            <div className='d-flex justify-content-between'>
              <span className='chat-name'>
                {detailRoom.type === 2 ? message.name : ''}
              </span>
              {detailUser.id === message.idUser && (
                <span className='cursor-pointer' onClick={() => setMenu(!menu)}>
                  {!menu ? <FaEllipsisH /> : <FaTimes />}
                </span>
              )}
            </div>
            <div className='mb-0' onClick={handleShow}>
              {menu ? (
                <div className='my-2'>
                  {!message.deletedAt && (
                    <Button
                      hasShadow
                      variant='danger'
                      onClick={() => handleDeleteMessage(message.id)}
                      isSmall
                      className='mr-2'
                    >
                      <FaTrash />
                    </Button>
                  )}
                </div>
              ) : message.deletedAt ? (
                <span className='deleted-message'>[ Deleted ]</span>
              ) : (
                <LazyImage className='message-img' src={message.image} />
              )}
            </div>
            <small className='clock d-inline-block mt-1 ml-3'>
              {filterTime(message.createdAt)}
            </small>
          </div>
        </div>
        <ZoomImage
          show={zoomImage}
          image={message.image}
          handleClose={handleClose}
        />
      </div>
    )
  }

  if (message.type === 5) {
    return (
      <div className={chatCardClass.join(' ')}>
        <div className='set'>
          <div className='contact-image'>
            <Avatar
              src={message.imageUser ? message.imageUser : ''}
              size='35px'
            />
          </div>
          <div className='message-text shadow-sm'>
            <div className='d-flex justify-content-between'>
              <span className='chat-name'>
                {detailRoom.type === 2 ? message.name : ''}
              </span>
              {detailUser.id === message.idUser && (
                <span className='cursor-pointer' onClick={() => setMenu(!menu)}>
                  {!menu ? <FaEllipsisH /> : <FaTimes />}
                </span>
              )}
            </div>
            <div className='mb-0'>
              {menu ? (
                <div className='my-2'>
                  {!message.deletedAt && (
                    <Button
                      hasShadow
                      variant='danger'
                      onClick={() => handleDeleteMessage(message.id)}
                      isSmall
                      className='mr-2'
                    >
                      <FaTrash />
                    </Button>
                  )}
                </div>
              ) : message.deletedAt ? (
                <span className='deleted-message'>[ Deleted ]</span>
              ) : (
                <>
                  <img
                    className='message-img'
                    src={JSON.parse(message.image).gif}
                    alt='sticker'
                  />
                  <a
                    href={JSON.parse(message.image).title}
                    target='_blank'
                    rel='noreferrer'
                    className='d-inline-block mt-1 font-10 giphy-link'
                  >
                    via GIPHY
                  </a>
                </>
              )}
            </div>
            <small className='clock d-inline-block mt-1 ml-3'>
              {filterTime(message.createdAt)}
            </small>
          </div>
        </div>
      </div>
    )
  }

  // message document
  if (message.type === 4) {
    return (
      <div className={chatCardClass.join(' ')}>
        <div className='set'>
          <div className='contact-image'>
            <Avatar
              src={message.imageUser ? message.imageUser : ''}
              size='35px'
            />
          </div>
          <div className='message-text shadow-sm'>
            <div className='d-flex justify-content-between'>
              <span className='chat-name'>
                {detailRoom.type === 2 ? message.name : ''}
              </span>
              {detailUser.id === message.idUser && (
                <span className='cursor-pointer' onClick={() => setMenu(!menu)}>
                  {!menu ? <FaEllipsisH /> : <FaTimes />}
                </span>
              )}
            </div>
            <div className='mb-0'>
              {menu ? (
                <div className='my-2'>
                  {!message.deletedAt && (
                    <Button
                      hasShadow
                      variant='danger'
                      onClick={() => handleDeleteMessage(message.id)}
                      isSmall
                      className='mr-2'
                    >
                      <FaTrash />
                    </Button>
                  )}
                </div>
              ) : (
                <span>Send Doc</span>
              )}
            </div>
            <small className='clock d-inline-block mt-1 ml-3'>
              {filterTime(message.createdAt)}
            </small>
          </div>
        </div>
      </div>
    )
  }

  // message default
  return (
    <div className={chatCardClass.join(' ')}>
      <div className='set'>
        <div className='contact-image'>
          <Avatar
            src={message.imageUser ? message.imageUser : ''}
            size='35px'
          />
        </div>
        <div className='message-text shadow-sm'>
          <div className='d-flex justify-content-between'>
            <span className='chat-name'>
              {detailRoom.type === 2 ? message.name : ''}
            </span>
            {detailUser.id === message.idUser && (
              <span className='cursor-pointer' onClick={() => setMenu(!menu)}>
                {!menu ? <FaEllipsisH /> : <FaTimes />}
              </span>
            )}
          </div>
          <div className='mb-0 d-inline'>
            {menu ? (
              <div className='my-2'>
                {!message.deletedAt && (
                  <Button
                    hasShadow
                    variant='danger'
                    onClick={() => handleDeleteMessage(message.id)}
                    isSmall
                    className='mr-2'
                  >
                    <FaTrash />
                  </Button>
                )}
              </div>
            ) : message.deletedAt ? (
              <span className='deleted-message'>[ Deleted ]</span>
            ) : (
              <span>{message.message}</span>
            )}
          </div>
          <small className='clock d-inline-block mt-1 ml-3'>
            {filterTime(message.createdAt)}
          </small>
        </div>
      </div>
    </div>
  )
}

export default ChatCard
