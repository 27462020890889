const { CHANGE_THEME, GET_STICKER } = require('../actionTypes')

const initialState = {
  darkMode: JSON.parse(localStorage.getItem('DARK_MODE')),
  stickers: [],
}

const globalReducers = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_THEME:
      return {
        ...state,
        darkMode: action.payload,
      }
    case GET_STICKER:
      return {
        ...state,
        stickers: action.payload,
      }
    default:
      return state
  }
}

export default globalReducers
