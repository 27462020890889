import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ILNoGroup } from '../../../assets'
import { ContactCard, Button, LazyImage } from '../../../components'
import { ModalGroup } from '../../../domain'
import { fetchDetailMessage } from '../../../state/message/messageActions'
import {
  fetchDetailRoom,
  fetchMemberRoom,
  getGroupRoom,
} from '../../../state/room/roomActions'

function Group() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getGroupRoom())
  }, [dispatch])
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)
  const [isLoading, setIsLoading] = useState(false)
  const [idRoom, setIdRoom] = useState(null)

  const handleDetail = async (id) => {
    setIdRoom(id)
    setIsLoading(true)
    await dispatch(fetchDetailMessage(id))
    await dispatch(fetchDetailRoom(id))
    await dispatch(fetchMemberRoom(id))
    setIsLoading(false)
  }
  const { groupRoom } = useSelector((state) => state.room)

  return (
    <>
      <div className='form-group mt-4'>
        <Button
          isPrimary
          hasShadow
          className='btn-sm mb-2'
          onClick={handleShow}
        >
          Create Group
        </Button>
        <input
          type='text'
          placeholder='Search public group...'
          className='form-control'
        />
      </div>
      <div>
        {groupRoom.length === 0 ? (
          <>
            <h5 className='text-center mt-5'>Group Empty</h5>
            <LazyImage
              src={ILNoGroup}
              isSvg
              alt='no group'
              className='img-fluid my-4'
            />
          </>
        ) : (
          groupRoom.map((room, id) => (
            <ContactCard
              room={room}
              key={id}
              isLoading={isLoading}
              targetId={idRoom}
              showDetail={handleDetail}
            />
          ))
        )}
      </div>
      {show && <ModalGroup show={show} handleClose={handleClose} />}
    </>
  )
}

export default Group
