import React from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { BsFillImageFill } from 'react-icons/bs'
import { FaUserAlt, FaUserPlus, FaPlus } from 'react-icons/fa'
import { IoMdDocument } from 'react-icons/io'
import { Avatar, Button, ZoomImage } from '../../../components'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toastError, toastSuccess } from '../../../utils/toastUtils'
import { fetchInviteUser, fetchKickUser } from '../../../state/room/roomActions'
import socketClient from '../../../utils/socket'
import { fetchMessageById } from '../../../state/message/messageActions'
import { MESSAGE_PUSH } from '../../../state/actionTypes'
import { swalConfirm } from '../../../utils/swal'

function TabSidebarRight() {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [zoomImage, setZoomImage] = useState(false)
  const [zoomImageShow, setZoomImageShow] = useState('')
  const handleClose = () => setZoomImage(false)
  const handleShow = (image) => {
    setZoomImageShow(image)
    setZoomImage(true)
  }
  const { detailRoom, memberRoom } = useSelector((state) => state.room)
  const detailUser = useSelector((state) => state.user.detailUser)
  const myFriend = useSelector((state) => state.friend.myFriend)
  const { imageMessage, documentMessage } = useSelector(
    (state) => state.message,
  )

  const filterFriendInRoom = () => {
    if (detailRoom.type === 2) {
      const listMember = memberRoom.map((member) => member.idUser)
      const filtered = myFriend.filter(function (e) {
        return this.indexOf(e.idFriend) < 0
      }, listMember)
      return filtered
    } else {
      return 'NAH'
    }
  }

  const getAdmin = () => {
    const admin = memberRoom.filter((member) => member.idUser === detailUser.id)
    return admin.length !== 0 ? admin[0] : {}
  }

  const handleInviteUser = async (idUser) => {
    setIsLoading(true)
    try {
      const data = {
        idUser,
        idRoom: detailRoom.idRoom,
      }
      await dispatch(fetchInviteUser(data))
      socketClient.emit('ts-invite-group', data)
      toastSuccess('The user has been successfully added to the room')
      setIsLoading(false)
    } catch (err) {
      toastError(err.response.data.message)
      setIsLoading(false)
    }
  }
  const pushMessage = async (id) => {
    const response = await dispatch(fetchMessageById(id))
    dispatch({ type: MESSAGE_PUSH, payload: response.data.data })
  }
  const handleKick = (data) => {
    swalConfirm(
      'Kick User',
      'Are you sure want kick this user',
      'question',
      async () => {
        setIsLoading(true)
        try {
          const dataKick = {
            idRoom: detailRoom.idRoom,
            name: detailUser.name,
            targetName: data.name,
            idTarget: data.idUser,
          }

          const response = await dispatch(fetchKickUser(dataKick))
          console.log(response)
          await pushMessage(response.data.data.idMessage)
          const dataSocket = {
            sendWhat: 11,
            room: detailRoom.idRoom,
            type: detailRoom.type,
            roomName: detailRoom.name,
            name: detailUser.name,
            email: detailUser.email,
            senderId: detailUser.id,
            message: `${detailUser.name} kick ${data.name} from group`,
            messageId: response.data.data.idMessage,
            targetKick: data.idUser,
          }
          socketClient.emit('sendMessage', dataSocket)
          setIsLoading(false)
        } catch (err) {
          toastError(err.response.data.message)
          setIsLoading(false)
        }
      },
    )
  }

  return (
    <div className='mt-4'>
      <Tabs
        defaultActiveKey={detailRoom.type === 1 ? 'profile' : 'member'}
        transition={false}
        id='noanim-tab-example'
      >
        {detailRoom.type === 2 && (
          <Tab eventKey='member' className='mt-3' title={<FaUserAlt />}>
            <p>Total Member {memberRoom.length}</p>
            {memberRoom.map((member, i) => (
              <div
                className='d-flex justify-content-between align-items-center mb-3'
                key={i}
              >
                <div className='d-flex align-items-center'>
                  <Avatar src={member.image} size='40px' />
                  <div className='ml-2'>
                    <p className='font-15 mb-0'>{member.name}</p>
                    <span className='font-12 text-muted'>
                      {member.status === 1 ? 'Admin' : 'Member'}
                    </span>
                  </div>
                </div>
                <div className='d-flex align-items-center'>
                  <p className='font-14 mr-2 mb-0'>
                    {member.statusOnline ? 'online' : 'offline'}
                  </p>
                  {member.status === 2 && getAdmin().status === 1 ? (
                    <Button
                      isSmall
                      variant='danger'
                      isLoading={isLoading}
                      onClick={() => handleKick(member)}
                    >
                      Kick
                    </Button>
                  ) : null}
                </div>
              </div>
            ))}
          </Tab>
        )}
        <Tab eventKey='profile' className='mt-3' title={<BsFillImageFill />}>
          <div className='d-flex flex-wrap-reverse justify-content-center'>
            {imageMessage.length > 0 ? (
              imageMessage.map((imageRoom, i) => (
                <span
                  className='cursor-pointer m-2'
                  key={i}
                  onClick={() => handleShow(imageRoom.image)}
                >
                  <Avatar square src={imageRoom.image} size='80px' />
                </span>
              ))
            ) : (
              <h5 className='my-4 text-center'>No Images</h5>
            )}
          </div>
        </Tab>
        <Tab eventKey='contact' className='mt-3' title={<IoMdDocument />}>
          {documentMessage.length > 0 ? (
            documentMessage.map((allDoc, i) => (
              <div className='d-flex flex-wrap-reverse' key={i}>
                <div className='w-100'>
                  <Link
                    to={allDoc.documentUrl}
                    className='d-block btn btn-sm btn-dark btn-block mb-2 font-13'
                    target='_blank'
                  >
                    {allDoc.documentName}
                  </Link>
                </div>
              </div>
            ))
          ) : (
            <h5 className='my-4 text-center'>No Document</h5>
          )}
        </Tab>
        {(detailRoom.type === 2 && getAdmin().status === 1) ||
        (detailRoom.type === 2 && detailRoom.memberCanInvite === 1) ? (
          <Tab eventKey='invite' className='mt-3' title={<FaUserPlus />}>
            <p>Invite Friends</p>
            {filterFriendInRoom().length === 0 ? (
              <h5 className='my-4 text-center'>
                Friends are already in the room or you don't have friends yet
              </h5>
            ) : (
              filterFriendInRoom().map((friend, i) => (
                <div
                  className='d-flex justify-content-between align-items-center mb-3'
                  key={i}
                >
                  <div className='d-flex align-items-center'>
                    <Avatar src={friend.image} size='40px' />
                    <div className='ml-2'>
                      <p className='font-15 mb-0'>{friend.friendName}</p>
                    </div>
                  </div>
                  <Button
                    isSmall
                    isPrimary
                    hasShadow
                    isLoading={isLoading}
                    onClick={() => handleInviteUser(friend.idFriend)}
                  >
                    <FaPlus />
                  </Button>
                </div>
              ))
            )}
          </Tab>
        ) : null}
      </Tabs>
      <ZoomImage
        image={zoomImageShow}
        show={zoomImage}
        handleClose={handleClose}
      />
    </div>
  )
}

export default TabSidebarRight
