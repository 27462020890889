import React from 'react'
import Skeleton from 'react-loading-skeleton'

const HomeSkeleton = () => {
  return (
    <div className='mt-4'>
      <div className='mb-3'>
        <Skeleton duration={2} count={3} />
      </div>
      <div className='mb-3'>
        <Skeleton duration={2} count={3} />
      </div>
      <div className='mb-3'>
        <Skeleton duration={2} count={3} />
      </div>
      <div className='mb-3'>
        <Skeleton duration={2} count={3} />
      </div>
      <div className='mb-3'>
        <Skeleton duration={2} count={3} />
      </div>
      <div className='mb-3'>
        <Skeleton duration={2} count={3} />
      </div>
      <div className='mb-3'>
        <Skeleton duration={2} count={3} />
      </div>
      <div className='mb-3'>
        <Skeleton duration={2} count={3} />
      </div>
      <div className='mb-3'>
        <Skeleton duration={2} count={3} />
      </div>
    </div>
  )
}

export default HomeSkeleton
