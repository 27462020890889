import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ILNoFriends } from '../../../assets'
import { ContactCard, LazyImage } from '../../../components'
import { fetchAddFriend } from '../../../state/friend/friendActions'
import socketClient from '../../../utils/socket'
import { toastError, toastSuccess } from '../../../utils/toastUtils'

function Users() {
  const dispatch = useDispatch()
  const { allUser, detailUser } = useSelector((state) => state.user)

  const handleAddFriend = async (id) => {
    try {
      await dispatch(fetchAddFriend(id))
      socketClient.emit('addFriend', {
        sender: detailUser.id,
        nameSender: detailUser.name,
        receiver: id,
      })
      toastSuccess('Friend request sent successfully')
    } catch (err) {
      toastError(err.response.data.message)
    }
  }

  return (
    <div>
      <div className='form-group mt-4'>
        <input
          type='text'
          placeholder='Search users...'
          className='form-control'
        />
      </div>
      <p>List Users</p>
      <div>
        {allUser.length === 0 ? (
          <>
            <LazyImage
              src={ILNoFriends}
              isSvg
              alt='no-friend'
              className='img-fluid'
            />
          </>
        ) : (
          allUser.map((user, id) => (
            <ContactCard
              type='list-users'
              handleAddFriend={handleAddFriend}
              room={user}
              key={id}
            />
          ))
        )}
      </div>
    </div>
  )
}

export default Users
