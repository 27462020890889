import {
  CHANGE_CALL_STATUS,
  UPDATE_DATA_PRIVATE_CALL,
  CLEAR_CALL_STATE,
  RESET_DATA_PRIVATE_CALL,
} from '../actionTypes'

const initialState = {
  isCalling: false,
  privateCall: {
    name: '',
    image: '',
    idRoom: null,
    status: '',
    answer: false,
    errorMessage: '',
  },
}

const callReducers = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_CALL_STATUS:
      return {
        ...state,
        isCalling: action.payload,
      }
    case UPDATE_DATA_PRIVATE_CALL:
      return {
        ...state,
        privateCall: {
          ...state.privateCall,
          ...action.payload,
        },
      }
    case RESET_DATA_PRIVATE_CALL:
      return {
        ...state,
        isCalling: false,
        privateCall: {
          name: '',
          image: '',
          idRoom: null,
          status: '',
          answer: false,
          errorMessage: '',
        },
      }
    case CLEAR_CALL_STATE:
      return {
        isCalling: false,
        privateCall: {
          name: '',
          image: '',
          idRoom: null,
          status: '',
          answer: false,
          errorMessage: '',
        },
      }
    default: {
      return state
    }
  }
}

export default callReducers
