import React from 'react'
import { LazyImage } from '../../../components'
import AboutImage from '../../../assets/images/illustration/Team spirit-pana.svg'
import './about-section.scss'
import {
  FaInstagram,
  FaFacebookSquare,
  FaTwitter,
  FaWhatsapp,
} from 'react-icons/fa'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'

const AboutSection = () => {
  useEffect(() => {
    AOS.init()
  }, [])
  return (
    <div className='container' id='about-us'>
      <div className='row'>
        <div
          className='col-lg-5 col-md-6 ml-auto'
          data-aos='fade-up'
          id='img-about'
        >
          <LazyImage
            src={AboutImage}
            alt='about-us'
            className='img-fluid'
            isSvg
          />
        </div>
        <div className='col-lg-5 col-md-6 mr-auto my-auto'>
          <span className='about-title' data-aos='fade-up'>
            About Us
          </span>
          <h2 className='about-sub-title' data-aos='fade-up'>
            Get To Know More About Mangga Chat
          </h2>
          <p className='about-description' data-aos='fade-up'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum, quas
            ut sit at impedit tempore voluptas fugiat? Nihil, corrupti vero.
          </p>
          <div className='about-social-media' data-aos='fade-up'>
            <a
              href='https://instagram.com/cepguna20'
              target='_blank'
              rel='noreferrer'
              className='about-sosmed-item'
            >
              <FaInstagram />
            </a>
            <a
              href='https://instagram.com/cepguna20'
              target='_blank'
              rel='noreferrer'
              className='about-sosmed-item'
            >
              <FaFacebookSquare />
            </a>
            <a
              href='https://instagram.com/cepguna20'
              target='_blank'
              rel='noreferrer'
              className='about-sosmed-item'
            >
              <FaTwitter />
            </a>
            <a
              href='https://instagram.com/cepguna20'
              target='_blank'
              rel='noreferrer'
              className='about-sosmed-item'
            >
              <FaWhatsapp />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutSection
