import {
  CHANGE_CALL_STATUS,
  CLEAR_CALL_STATE,
  UPDATE_DATA_PRIVATE_CALL,
  RESET_DATA_PRIVATE_CALL,
} from '../actionTypes'

export const changeCallStatus = (status) => {
  return { type: CHANGE_CALL_STATUS, payload: status }
}

export const clearCallState = () => {
  return { type: CLEAR_CALL_STATE }
}

export const updateDataPrivateCall = (data) => {
  return { type: UPDATE_DATA_PRIVATE_CALL, payload: data }
}

export const resetDataPrivateCall = () => {
  return { type: RESET_DATA_PRIVATE_CALL }
}
