import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FaHome, FaUserFriends, FaUserPlus } from 'react-icons/fa'
import { RiDiscussFill } from 'react-icons/ri'
import './main-menu.scss'
import { useSelector } from 'react-redux'

function MainMenu() {
  const { location } = useHistory()
  const getCurrentLocation = location.pathname
  const friendRequest = useSelector(state => state.friend.friendRequest)

  return (
    <>
      <ul className="nav nav-pills shadow main-menu nav-fill">
        <li className="nav-item">
          <Link className={`nav-link ${getCurrentLocation === '/home' ? 'active' : null}`} title="Home" to="/home"><FaHome /></Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${getCurrentLocation === '/home/group' ? 'active' : null}`} title="Group" to="/home/group"><RiDiscussFill /></Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${getCurrentLocation === '/home/friends' ? 'active' : null}`} title="Friends" to="/home/friends"><FaUserFriends /> {friendRequest.length > 0 && (<span className="font-12">({friendRequest.length})</span>)}</Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${getCurrentLocation === '/home/users' ? 'active' : null}`} title="Users" to="/home/users"><FaUserPlus /></Link>
        </li>
      </ul>
    </>
  )
}

export default MainMenu
