import Api from './apiHandler'
const BASE = 'stories'

const storyApi = {
  getFriendStory: () => Api.get(`${BASE}/friend`),
  getDetailStory: (id, page) =>
    Api.get(`${BASE}/story-detail/${id}?page=${page || 1}`),
  getViewers: (id) => Api.get(`${BASE}/story-view/${id}`),
  addStory: (data) => Api.post(`${BASE}`, data),
  updateViewers: (id) => Api.patch(`${BASE}/view/${id}`),
  deleteStory: (id) => Api.delete(`${BASE}/${id}`),
}

export default storyApi
