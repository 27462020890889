import React, { useEffect, useState } from 'react'
import ChatCard from '../ChatCard'
import ChatHeader from '../ChatHeader'
import ChatInput from '../ChatInput'
import SidebarRight from '../SidebarRight'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner } from 'react-bootstrap'
import { FaAngleDown } from 'react-icons/fa'
import {
  fetchDeleteMessage,
  fetchDetailMessage,
  fetchNextMessage,
} from '../../../state/message/messageActions'
import { GET_DETAIL_MESSAGE } from '../../../state/actionTypes'
import './detail-chat.scss'
import { fetchLastSeenRoom } from '../../../state/room/roomActions'
import socketClient from '../../../utils/socket'
import { toastError } from '../../../utils/toastUtils'

function ChatDetail() {
  const [showSidebar, setShowSidebar] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const handleShowSidebar = () => setShowSidebar(!showSidebar)
  const handleCloseSidebar = () => setShowSidebar(!showSidebar)
  const detailRoom = useSelector((state) => state.room.detailRoom)
  const detailUser = useSelector((state) => state.user.detailUser)
  const detailMessage = useSelector((state) => state.message.detailMessage)

  const deleteMessage = async (id) => {
    console.log('delete')
    try {
      await dispatch(fetchDeleteMessage(id))
      const messageExist = detailMessage.find((message) => {
        return message.id === id
      })
      if (messageExist) {
        const newMessage = detailMessage.map((message) => {
          if (message.id === id) {
            message.deletedAt = new Date()
          }
          return message
        })
        dispatch({ type: GET_DETAIL_MESSAGE, payload: newMessage })
      }
      socketClient.emit('sendMessage', {
        sendWhat: 10,
        messageId: id,
        room: detailRoom.idRoom,
        type: detailRoom.type,
        roomName: detailRoom.name,
        name: detailUser.name,
        email: detailUser.email,
        senderId: detailUser.id,
        message: 'Delete message',
      })
    } catch (err) {
      toastError(err.response.data.message)
    }
  }

  useEffect(() => {
    const detailMsg = async () => {
      const response = await dispatch(fetchDetailMessage(detailRoom.idRoom))
      if (response.data._links.next) {
        setHasMore(true)
      }
    }
    detailMsg()
  }, [dispatch, detailRoom])
  const messages = useSelector((state) => state.message.detailMessage)

  const fetchMoreData = async (page) => {
    setPage(page)
    const response = await dispatch(fetchNextMessage(detailRoom.idRoom, page))
    if (response.data.data.length !== 0) {
      dispatch({
        type: GET_DETAIL_MESSAGE,
        payload: [...messages, ...response.data.data],
      })
    } else {
      setHasMore(false)
    }
  }

  useEffect(() => {
    dispatch(fetchLastSeenRoom(detailRoom.idRoom))
  }, [messages, dispatch, detailRoom])

  return (
    <div className='col-lg-8 col-md-7 bg-detail-chat h-chat p-0 right-chat'>
      <ChatHeader handleSidebar={handleShowSidebar} />
      <div
        className='main-chat px-4 d-flex flex-column-reverse'
        id='infinite-scroll-comp'
      >
        <InfiniteScroll
          dataLength={messages.length}
          next={() => fetchMoreData(page + 1)}
          style={{ display: 'flex', flexDirection: 'column-reverse' }}
          inverse={true}
          hasMore={hasMore}
          loader={
            <div className='text-center my-3'>
              <Spinner animation='border' />
            </div>
          }
          scrollableTarget='infinite-scroll-comp'
          className='overflow-hidden'
        >
          {messages.map((message, i) => (
            <ChatCard message={message} deleteMessage={deleteMessage} key={i} />
          ))}
        </InfiniteScroll>

        <div className='chat-scroll-to-bottom'>
          <span>
            <FaAngleDown />
          </span>
        </div>
      </div>
      <div className='bottom-input'>
        <ChatInput />
      </div>
      {showSidebar && (
        <SidebarRight
          isSidebarShow={showSidebar}
          closeSidebar={handleCloseSidebar}
        />
      )}
    </div>
  )
}

export default ChatDetail
