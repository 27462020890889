import { toast } from 'react-toastify'

const option = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

export const toastInfo = (message) => {
  toast.info(message, option)
}
export const toastSuccess = (message) => {
  toast.success(message, option)
}
export const toastWarning = (message) => {
  toast.warn(message, option)
}
export const toastError = (message) => {
  toast.error(message, option)
}
export const toastDefault = (message) => {
  toast(message, option)
}
export const toastDark = (message) => {
  toast.dark(message, option)
}