import React, { useEffect, useState } from 'react'
import { Modal, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../../components'
import {
  fetchAddStory,
  fetchDetailStory,
} from '../../../state/story/storyActions'
import socketClient from '../../../utils/socket'
import { toastError, toastSuccess } from '../../../utils/toastUtils'

function ModalAddStory({ show, handleClose }) {
  const dispatch = useDispatch()
  const [image, setImage] = useState(null)
  const [text, setText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const idUser = useSelector((state) => state.user.detailUser.id)

  useEffect(() => {
    setImage(null)
    setText('')
  }, [show])

  const handleAdd = async (e) => {
    e.preventDefault()
    if (!image) {
      toastError('Image required')
    } else {
      setIsLoading(true)
      const formData = new FormData()
      formData.append('text', text)
      formData.append('image', image)
      try {
        await dispatch(fetchAddStory(formData))
        await dispatch(fetchDetailStory(idUser, 1, true))
        socketClient.emit('transfer-signal-story', { idUser, type: 'add' })
        setIsLoading(false)
        toastSuccess('Data Added')
        setText('')
        setImage(null)
        handleClose()
      } catch (err) {
        toastError(err.response.data.message)
        setIsLoading(false)
      }
    }
  }

  return (
    <Modal show={show} animation={false} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title id='example-custom-modal-styling-title'>
          Add Story
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleAdd}>
          <div className='form-group'>
            <label>Text</label>
            <input
              type='text'
              className='form-control'
              onChange={(e) => setText(e.target.value)}
            />
          </div>
          <div className='form-group'>
            <label>File</label>
            <Form.File
              id='custom-file'
              label='Select image'
              custom
              onChange={(e) => setImage(e.target.files[0])}
            />
            {image && (
              <span className='badge badge-primary'>1 file selected</span>
            )}
          </div>
          <div className='float-right'>
            <button
              type='button'
              className='btn btn-secondary shadow mr-2'
              onClick={handleClose}
            >
              Close
            </button>
            <Button type='submit' isPrimary hasShadow isLoading={isLoading}>
              Add
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default ModalAddStory
