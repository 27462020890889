import Api from './apiHandler'
const BASE = 'rooms'

const RoomApi = {
  getMyRoom: () => Api.get(`${BASE}/my-room`),
  getDetailRoom: (id) => Api.get(`${BASE}/${id}`),
  getMemberRoom: (id) => Api.get(`${BASE}/member/${id}`),
  addPublicRoom: (data) => Api.post(`${BASE}/public-room`, data),
  changeNotification: (id) => Api.patch(`${BASE}/change-notif/${id}`),
  changeStatusInvite: (id) => Api.patch(`${BASE}/invite-status/${id}`),
  inviteUser: (data) => Api.post(`${BASE}/invite`, data),
  updateRoom: (id, data) => Api.patch(`${BASE}/${id}`, data),
  updateLastSeen: (id) => Api.patch(`${BASE}/last-seen-room/${id}`),
  leaveRoom: (data) => Api.patch(`${BASE}/leave-room`, data),
  kickUser: (data) => Api.patch(`${BASE}/kick-user`, data),
}

export default RoomApi
