import React, { Component } from 'react'
import { MainMenu } from '../../domain/Home'
import NavbarLeft from '../../domain/Home/NavbarLeft'
import { Switch, Route, withRouter } from 'react-router-dom'
import Setting from './Setting'
import Users from './Users'
import Friends from './Friends'
import Group from './Group'
import Dashboard from './Dashboard'
import Stories from '../../domain/Stories'
import { ChatDetail, ChatEmpty } from '../../domain'
import { connect } from 'react-redux'
import { changeFirstLoading } from '../../state/auth/authActions'
import { fetchAllUser, fetchDetailUser } from '../../state/user/userActions'
import { fetchStickerGiphy } from '../../state//global/globalActions'
import {
  fetchDetailRoom,
  fetchMemberRoom,
  fetchMyRoom,
  getGroupRoom,
  getPrivateRoom,
} from '../../state/room/roomActions'
import { fetchMyFriend } from '../../state/friend/friendActions'
import {
  fetchDetailStory,
  fetchFriendStory,
} from '../../state/story/storyActions'
import socketClient from '../../utils/socket'
import {
  CLEAR_DETAIL_MESSAGE,
  CLEAR_DETAIL_ROOM,
  GET_DETAIL_MESSAGE,
  MESSAGE_PUSH,
} from '../../state/actionTypes'
import {
  fetchDetailMessage,
  fetchDocumentMessage,
  fetchImageMessage,
  fetchMessageById,
} from '../../state/message/messageActions'
import { toastError, toastInfo } from '../../utils/toastUtils'
import StorySkeleton from '../../domain/Stories/StorySkeleton'
import HomeSkeleton from '../../domain/Home/HomeSkeleton'
import {
  changeCallStatus,
  updateDataPrivateCall,
} from '../../state/call/callActions'

class ClassHome extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showModalCall: false,
    }
  }

  handleCloseModalCall = () => {
    this.props.handleChangeStatusCall(false)
  }

  async componentDidMount() {
    if (!this.props.authState.token) {
      this.props.history.push('/auth/signin')
    }

    this.props.changeFirstLoading(true)
    await this.props.getDetailUser()
    await this.props.getMyRoom()
    const roomId = this.props.roomState.myRoom.map((room) => room.idRoom)
    socketClient.emit('join-room', roomId)
    this.props.getGroupRoom()
    this.props.getPrivateRoom()
    await this.props.getMyFriend()
    await this.props.getFriendStories()
    await this.props.getAllUsers()
    await this.props.getMyStories(this.props.detailUser.id)
    this.props.changeFirstLoading(false)
    await this.props.getStickers()

    socketClient.on('sendMessageHandle', async (data) => {
      if (this.props.roomState.detailRoom.idRoom === data.room) {
        if (data.sendWhat === 10) {
          const messageExist = this.props.detailMessage.find((message) => {
            return message.id === data.messageId
          })
          if (messageExist) {
            const newMessage = this.props.detailMessage.map((message) => {
              if (message.id === data.messageId) {
                message.deletedAt = new Date()
              }
              return message
            })
            this.props.handleDeleteMessage(newMessage)
          }
        } else {
          const response = await this.props.getMessageById(data.messageId)
          this.props.sendMessage(response.data.data)
          if (data.sendWhat === 11) {
            if (data.targetKick === this.props.detailUser.id) {
              toastInfo(data.message)
              this.props.clearDetailMessage()
              this.props.clearDetailRoom()
            } else {
              await this.props.getMemberRoom(data.room)
            }
          }
          if (data.sendWhat === 12) {
            await this.props.getMemberRoom(data.room)
          }
          if (data.sendWhat === 3) {
            await this.props.getImageMessage(data.room)
          } else if (data.sendWhat === 4) {
            await this.props.getDocumentMessage(data.room)
          }
        }
      }
      await this.props.getMyRoom()
    })

    socketClient.on('notifDeleteMessage', (data) => {
      this.props.getMyRoom()
    })
    socketClient.on('return-signal-story', (data) => {
      const friendExists = this.props.myFriend.find((friend) => {
        return friend.idFriend === data.idUser
      })
      const friendStoryExists = this.props.friendStory.find((story) => {
        return story.idUser === data.idUser
      })
      if (data.type === 'delete') {
        if (friendExists) {
          this.props.getFriendStories()
        }
      } else {
        if (friendExists) {
          if (!friendStoryExists) {
            this.props.getFriendStories()
          }
        }
      }
    })

    socketClient.on('notifPesan', ({ notif, data }) => {
      if (!this.props.roomState.detailRoom.idRoom) {
      }

      this.props.roomState.myRoom.map((item) => {
        if (item.idRoom === data.room && item.notification === 1) {
          if (this.props.roomState.detailRoom.idRoom !== item.idRoom) {
            toastInfo(notif)
          }
        }
        return null
      })
    })

    socketClient.on('notifAddFriend', (data) => {
      if (this.props.detailUser.id === data.receiver) {
        toastInfo(`${data.nameSender} want to be your friend`)
        this.props.getMyFriend()
        this.props.getAllUsers()
      }
    })

    socketClient.on('notifAccFriend', async (data) => {
      if (this.props.detailUser.id === data.receiver) {
        toastInfo(`${data.nameSender} accepts your friendship`)
        await this.props.getMyFriend()
        await this.props.getMyRoom()
        await this.props.getFriendStories()
        socketClient.emit('joinSingleRoom', data.idRoom)
      }
    })

    socketClient.on('notifRejectFriend', async (data) => {
      if (this.props.detailUser.id === data.receiver) {
        await this.props.getMyFriend()
        toastError(`Friend request rejected by ${data.nameSender} `)
      }
    })

    socketClient.on('notifDeleteFriend', async (data) => {
      if (this.props.detailUser.id === data.receiver) {
        await this.props.getMyFriend()
        await this.props.getAllUsers()
      }
    })

    socketClient.on('rs-invite-status-room', async (idRoom) => {
      if (this.props.roomState.detailRoom.idRoom === idRoom) {
        await this.props.getDetailRoom(idRoom)
      }
    })

    socketClient.on('rs-invite-group', async (data) => {
      if (this.props.detailUser.id === data.idUser) {
        socketClient.emit('joinSingleRoom', data.idRoom)
        await this.props.getMyRoom()
      }
    })

    socketClient.on('rs-unfriend', async (data) => {
      if (this.props.roomState.detailRoom.idRoom === data.idRoom) {
        this.props.clearDetailMessage()
        this.props.clearDetailRoom()
        this.props.getMyRoom()
        this.props.getMyFriend()
        this.props.getAllUsers()
        toastInfo(`${data.nameSender} has unfriend you`)
      } else {
        this.props.getMyRoom()
        this.props.getMyFriend()
        this.props.getAllUsers()
        toastInfo(`${data.nameSender} has unfriend you`)
      }
    })

    socketClient.on('pending-private-call', (data) => {
      console.log('Masuk yes', data.idRoom)
      this.props.handleChangeStatusCall(true)
      this.props.updateDataPrivateCall(data)
    })

    socketClient.on('handle-missed-private-call', () => {
      this.props.handleChangeStatusCall(false)
    })
  }

  render() {
    const { roomState, authState } = this.props
    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-4 col-md-5 px-4 left-content-home max-vh-100 overflow-auto'>
            <div
              className={`${
                roomState.detailRoom.idRoom ? 'mobile' : 'dashboard-mobile'
              }`}
            >
              {this.props.location.pathname !== '/home/setting' ? (
                <>
                  <NavbarLeft newTab={false} />
                  {authState.firstLoading ? (
                    <div className='message-by d-flex overflow-auto py-2 align-items-center'>
                      <StorySkeleton />
                    </div>
                  ) : (
                    <Stories />
                  )}
                  <div className='main-menu-parent'>
                    <MainMenu />
                  </div>
                </>
              ) : null}
              {authState.firstLoading ? (
                <HomeSkeleton />
              ) : (
                <Switch>
                  <Route exact path={`/home/setting`}>
                    <Setting />
                  </Route>
                  <Route exact path={`/home/users`}>
                    <Users />
                  </Route>
                  <Route exact path={`/home/friends`}>
                    <Friends />
                  </Route>
                  <Route exact path={`/home/group`}>
                    <Group />
                  </Route>
                  <Route exact path={`/home`}>
                    <Dashboard />
                  </Route>
                </Switch>
              )}
            </div>
          </div>
          {roomState.detailRoom.idRoom ? <ChatDetail /> : <ChatEmpty />}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    roomState: state.room,
    detailMessage: state.message.detailMessage,
    authState: state.auth,
    myFriend: state.friend.myFriend,
    friendStory: state.story.friendStories,
    detailUser: state.user.detailUser,
    callState: state.call,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeFirstLoading: (status) => dispatch(changeFirstLoading(status)),
    getDetailUser: () => dispatch(fetchDetailUser()),
    getDetailMessage: (id) => dispatch(fetchDetailMessage(id)),
    getImageMessage: (id) => dispatch(fetchImageMessage(id)),
    getDocumentMessage: (id) => dispatch(fetchDocumentMessage(id)),
    getMyRoom: () => dispatch(fetchMyRoom()),
    getGroupRoom: () => dispatch(getGroupRoom()),
    getPrivateRoom: () => dispatch(getPrivateRoom()),
    getMyFriend: () => dispatch(fetchMyFriend()),
    getFriendStories: () => dispatch(fetchFriendStory()),
    getDetailRoom: (idRoom) => dispatch(fetchDetailRoom(idRoom)),
    getMemberRoom: (idRoom) => dispatch(fetchMemberRoom(idRoom)),
    getMyStories: (id) => dispatch(fetchDetailStory(id, 1, true)),
    getAllUsers: () => dispatch(fetchAllUser()),
    sendMessage: (data) => dispatch({ type: MESSAGE_PUSH, payload: data }),
    getMessageById: (id) => dispatch(fetchMessageById(id)),
    clearDetailMessage: () => dispatch({ type: CLEAR_DETAIL_MESSAGE }),
    clearDetailRoom: () => dispatch({ type: CLEAR_DETAIL_ROOM }),
    getStickers: () => dispatch(fetchStickerGiphy()),
    handleDeleteMessage: (data) =>
      dispatch({ type: GET_DETAIL_MESSAGE, payload: data }),
    handleChangeStatusCall: (status) => dispatch(changeCallStatus(status)),
    updateDataPrivateCall: (data) => dispatch(updateDataPrivateCall(data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ClassHome))
