import React from 'react'
import { FaVideo, FaGripHorizontal } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { ICBack } from '../../../assets'
import { Avatar } from '../../../components'
import {
  CLEAR_DETAIL_MESSAGE,
  CLEAR_DETAIL_ROOM,
} from '../../../state/actionTypes'
import filterFriend from '../../../utils/filterFriend'
import './chat-header.scss'

function ChatHeader(props) {
  const dispatch = useDispatch()
  const handleClearMessage = () => {
    dispatch({ type: CLEAR_DETAIL_MESSAGE })
    dispatch({ type: CLEAR_DETAIL_ROOM })
  }
  const detailRoom = useSelector((state) => state.room.detailRoom)
  return (
    <div className='card chat-header border-0 rounded-0'>
      <div className='card-body d-flex justify-content-between align-items-center'>
        <div className='d-flex'>
          <button
            type='button'
            className='btn'
            onClick={() => handleClearMessage()}
          >
            <img src={ICBack} className='back-icon' alt='back' />
          </button>
          {detailRoom.type === 2 ? (
            <Avatar text={detailRoom.name} size='40px' />
          ) : (
            <Avatar src={filterFriend(detailRoom).image} size='40px' />
          )}
          <div className='d-flex flex-column justify-content-between ml-2'>
            <p className='mb-0 weight-500'>
              {detailRoom.type === 2
                ? detailRoom.name
                : filterFriend(detailRoom).friendName}
            </p>
            <span className='color-lb font-12'>
              {detailRoom.type === 2
                ? 'Group'
                : filterFriend(detailRoom).statusOnline === 1
                ? 'Online'
                : 'Offline'}
            </span>
          </div>
        </div>
        <div>
          <button className='btn'>
            <FaVideo className='color-lb' size='20px' />
          </button>
          <button className='btn' onClick={props.handleSidebar}>
            <FaGripHorizontal className='color-lb' size='20px' />
          </button>
        </div>
      </div>
    </div>
  )
}

export default ChatHeader
