import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { toastError, toastSuccess } from '../../../utils/toastUtils'
import { Button, InputError } from '../../../components'
import { FaLock, FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import '../auth.scss'
import {
  fetchResetPassword,
  fetchVerifyResetPassword,
} from '../../../state/auth/authActions'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'

function SignIn() {
  const [isEyeOpen, setIsEyeOpen] = useState(false)
  const initialValues = {
    password: '',
    verifyPassword: '',
  }
  const validationSchema = Yup.object({
    password: Yup.string().min(6).required(),
    verifyPassword: Yup.string()
      .min(6)
      .required('verify password is a required field'),
  })
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const query = new URLSearchParams(useLocation().search)
  const token = query.get('token')
  useEffect(() => {
    if (!token) {
      history.push('/auth/signin')
      toastError('Token required')
    } else {
      const checkToken = async () => {
        try {
          await dispatch(fetchVerifyResetPassword({ token }))
        } catch (err) {
          toastError(err.response.data.message)
          history.push('/auth/signin')
        }
      }
      checkToken()
    }
  }, [dispatch, history, token])

  const handleSubmit = async (values) => {
    setIsLoading(true)
    const data = {
      password: values.password,
      confirmPassword: values.verifyPassword,
      token,
    }
    try {
      await dispatch(fetchResetPassword(data))
      toastSuccess('Password changed successfully')
      history.push('/auth/signin')
      setIsLoading(false)
    } catch (err) {
      values.password = ''
      values.verifyPassword = ''
      toastError(err.response.data.message)
      setIsLoading(false)
    }
  }

  return (
    <>
      <h2 className='auth-title'>Reset Password</h2>
      <p className='auth-desc'>Please reset your password</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <ErrorMessage name='password' component={InputError} />
          <div className='form-group position-relative'>
            <div className='icon-auth-left'>
              <FaLock />
            </div>
            <div
              className='icon-auth-right'
              onClick={() => setIsEyeOpen(!isEyeOpen)}
            >
              {isEyeOpen ? <FaRegEyeSlash /> : <FaRegEye />}
            </div>
            <Field
              type={isEyeOpen ? 'text' : 'password'}
              name='password'
              placeholder='New Password'
              className='form-control auth-input'
            />
          </div>
          <ErrorMessage name='verifyPassword' component={InputError} />
          <div className='form-group position-relative'>
            <div className='icon-auth-left'>
              <FaLock />
            </div>
            <Field
              name='verifyPassword'
              type={isEyeOpen ? 'text' : 'password'}
              placeholder='Verify New Password'
              className='form-control auth-input'
            />
          </div>
          <Button
            type='submit'
            isPrimary
            isLoading={isLoading}
            isBlock
            hasShadow
            className='btn-auth mt-4'
          >
            Reset
          </Button>
        </Form>
      </Formik>
    </>
  )
}

export default SignIn
