import React, { useState } from 'react'
import TabSidebarRight from '../TabSidebarRight'
import { FaTimes } from 'react-icons/fa'
import { Avatar, Button } from '../../../components'
import './sidebar-right.scss'
import { useDispatch, useSelector } from 'react-redux'
import { FaPen, FaDoorOpen } from 'react-icons/fa'
import { toastError, toastSuccess } from '../../../utils/toastUtils'
import {
  fetchLeaveGroup,
  fetchStatusInvite,
} from '../../../state/room/roomActions'
import ModalSettingGroup from '../ModalSettingGroup'
import filterFriend from '../../../utils/filterFriend'
import socketClient from '../../../utils/socket'
import { swalConfirm } from '../../../utils/swal'
import {
  CLEAR_DETAIL_MESSAGE,
  CLEAR_DETAIL_ROOM,
} from '../../../state/actionTypes'

function SidebarRight(props) {
  const dispatch = useDispatch()
  const [showModalSetting, setShowModalSetting] = useState(false)
  const handleCloseModalSetting = () => setShowModalSetting(false)
  const handleShowModalSetting = () => setShowModalSetting(true)
  const detailRoom = useSelector((state) => state.room.detailRoom)
  const memberInRoom = useSelector((state) => state.room.memberRoom)
  const detailUser = useSelector((state) => state.user.detailUser)
  const [isLoading, setIsLoading] = useState(false)
  const checkAdmin = (idUser) => {
    const isAdmin = memberInRoom.filter((member) => member.idUser === idUser)[0]
    return isAdmin.status
  }
  const handleMemberCanInvite = async () => {
    try {
      await dispatch(fetchStatusInvite(detailRoom.idRoom))
      socketClient.emit('ts-invite-status-room', detailRoom.idRoom)
      toastSuccess('Invite status updated successfully')
    } catch (err) {
      toastError(err.response.data.message)
    }
  }

  const handleLeave = () => {
    swalConfirm(
      'Leave Group',
      'Are you sure want to leave the group',
      'question',
      async () => {
        setIsLoading(true)
        try {
          const data = {
            idRoom: detailRoom.idRoom,
            name: detailUser.name,
          }
          const response = await dispatch(fetchLeaveGroup(data))
          dispatch({ type: CLEAR_DETAIL_MESSAGE })
          dispatch({ type: CLEAR_DETAIL_ROOM })
          const dataSocket = {
            sendWhat: 12,
            room: detailRoom.idRoom,
            type: detailRoom.type,
            roomName: detailRoom.name,
            name: detailUser.name,
            email: detailUser.email,
            senderId: detailUser.id,
            message: `${detailUser.name} leave group`,
            messageId: response.data.data.idMessage,
          }
          socketClient.emit('sendMessage', dataSocket)
          socketClient.emit('ts-leave-group', detailRoom.idRoom)
          setIsLoading(false)
        } catch (err) {
          toastError(err.response.data.message)
          setIsLoading(false)
        }
      },
    )
  }

  return (
    <div className={`sidebar-right${props.isSidebarShow ? ' active' : ''}`}>
      <div className='header-side-right'>
        <span onClick={props.closeSidebar}>
          <FaTimes size='24px' />
        </span>
      </div>
      <div className='main-side-right'>
        <div className='text-center'>
          <h5 className='name-or-group'>
            {detailRoom.type === 2
              ? detailRoom.name
              : filterFriend(detailRoom).friendName}
          </h5>
          {detailRoom.type === 2 ? (
            <Avatar rounded='sm' text={detailRoom.name} />
          ) : (
            <Avatar rounded='sm' src={filterFriend(detailRoom).image} />
          )}
        </div>
        <div className='border-side-right pb-3 mt-5'>
          <div>
            <p className='font-16 weight-500 mb-1'>
              {detailRoom.type === 2 ? (
                <>
                  {detailRoom.name}
                  {checkAdmin(detailUser.id) === 1 && (
                    <span
                      className='cursor-pointer ml-2'
                      onClick={handleShowModalSetting}
                    >
                      <FaPen size='14px' />
                    </span>
                  )}
                </>
              ) : (
                filterFriend(detailRoom).friendName
              )}
            </p>
            <p className='phone-number weight-400 mb-1 font-13'>
              {detailRoom.type === 2
                ? 'Group'
                : filterFriend(detailRoom).statusOnline === 1
                ? 'Online'
                : 'Offline'}
            </p>
          </div>
          {detailRoom.type === 2 ? (
            <>
              <div className='mt-4'>
                <p className='font-16 weight-500 mb-1'>Description</p>
                <div className='font-13 mb-1 weight-400 align-items-center d-flex'>
                  {!detailRoom.description ? 'Empty' : detailRoom.description}
                </div>
              </div>
              {checkAdmin(detailUser.id) === 1 && (
                <div className='mt-4'>
                  <p className='font-16 weight-500 mb-1'>Setting</p>
                  <div>
                    <div className='font-13 mb-1 weight-400 align-items-center d-flex'>
                      <input
                        type='checkbox'
                        className='mr-1'
                        onChange={handleMemberCanInvite}
                        checked={
                          detailRoom.memberCanInvite === 1 ? true : false
                        }
                      />
                      Member can invite friend
                    </div>
                  </div>
                </div>
              )}
              {checkAdmin(detailUser.id) === 2 && (
                <Button
                  variant='danger'
                  isSmall
                  className='mt-4'
                  isLoading={isLoading}
                  onClick={handleLeave}
                >
                  Leave the group <FaDoorOpen />
                </Button>
              )}
            </>
          ) : (
            <>
              <div className='mt-4'>
                <p className='font-16 weight-500 mb-1'>Phone Number</p>
                <p className='phone-number weight-400 mb-1 font-13'>
                  {filterFriend(detailRoom).phoneNumber
                    ? filterFriend(detailRoom).phoneNumber
                    : 'Belum diset'}
                </p>
              </div>
              <div className='mt-4' v-if='getDetailRoom.type === 1'>
                <p className='font-16 weight-500 mb-1'>Bio</p>
                <p className='phone-number weight-400 mb-1 font-13'>
                  {filterFriend(detailRoom).bio
                    ? filterFriend(detailRoom).bio
                    : 'Belum diset'}
                </p>
              </div>
            </>
          )}
        </div>
        <TabSidebarRight />
      </div>
      {showModalSetting && (
        <ModalSettingGroup
          show={showModalSetting}
          handleClose={handleCloseModalSetting}
        />
      )}
    </div>
  )
}

export default SidebarRight
