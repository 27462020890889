import {
  CHANGE_FIRST_LOADING,
  LOGOUT_USER,
  SIGNIN_SUCCESS,
} from '../actionTypes'

const initialState = {
  token: localStorage.getItem('token') || null,
  firstLoading: false,
}

const authReducers = (state = initialState, action) => {
  switch (action.type) {
    case SIGNIN_SUCCESS:
      return {
        ...state,
        token: action.payload,
      }
    case LOGOUT_USER:
      return {
        ...state,
        token: null,
      }
    case CHANGE_FIRST_LOADING:
      return {
        ...state,
        firstLoading: action.payload,
      }
    default:
      return state
  }
}

export default authReducers
