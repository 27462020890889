import React from 'react'
import { Link } from 'react-scroll'
import './footer-landing.scss'

const FooterLanding = () => {
  return (
    <footer className="footer py-5">
      <div className="container">
        <div className="main-footer">
          <p className="mb-0 text-center font-15 text-muted">Copyright 2020 &copy; Mangga Chat All Right Reserved</p>
          <div className="menu-footer">
            <Link 
              to="hero-section" 
              smooth={true} 
              duration={800} 
              spy={true} 
              exact='true' 
              offset={-30} 
              activeClass="active"
              className="text-muted font-15 cursor-pointer mr-3"
            >Home</Link>
            <Link 
              to="features" 
              smooth={true} 
              duration={800} 
              spy={true} 
              exact='true' 
              offset={-30} 
              activeClass="active"
              className="text-muted font-15 cursor-pointer mr-3"
            >Features</Link>
            <Link 
              to="join" 
              smooth={true} 
              duration={800} 
              spy={true} 
              exact='true' 
              activeClass="active"
              className="text-muted font-15 cursor-pointer mr-3"
            >Join</Link>
            <Link 
              to="about-us" 
              smooth={true} 
              duration={800} 
              spy={true} 
              exact='true' 
              offset={10} 
              activeClass="active"
              className="text-muted font-15 cursor-pointer"
            >About Us</Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterLanding
