const {
  GET_FRIEND_STORY,
  GET_MY_STORY,
  GET_DETAIL_STORY,
  CLEAR_STORY_STATE,
  GET_CURRENT_STORY,
  GET_VIEWERS_STORY,
} = require('../actionTypes')

const initialState = {
  friendStories: [],
  detailStory: [],
  storyLoading: false,
  currentStory: {},
  myStory: [],
  viewers: [],
}

const storyReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_FRIEND_STORY:
      return {
        ...state,
        friendStories: action.payload,
      }
    case GET_MY_STORY:
      return {
        ...state,
        myStory: action.payload,
      }
    case GET_DETAIL_STORY:
      return {
        ...state,
        currentStory: action.payload,
      }
    case GET_VIEWERS_STORY:
      return {
        ...state,
        viewers: action.payload,
      }
    case GET_CURRENT_STORY:
      return {
        ...state,
        currentStory: action.payload,
      }
    case CLEAR_STORY_STATE:
      return {
        friendStories: [],
        detailStory: [],
        storyLoading: false,
        currentStory: {},
        myStory: [],
        viewers: [],
      }
    default:
      return state
  }
}

export default storyReducers
