import React from 'react'
import { Modal } from 'react-bootstrap'
import { ImLocation2 } from 'react-icons/im'
import { BsFillImageFill } from 'react-icons/bs'
import { IoMdDocument } from 'react-icons/io'
import { swalConfirm } from '../../../utils/swal'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchMessageById,
  fetchSendMessage,
} from '../../../state/message/messageActions'
import { toastError } from '../../../utils/toastUtils'
import './modal-input.scss'
import socketClient from '../../../utils/socket'
import { MESSAGE_PUSH } from '../../../state/actionTypes'

function ModalInputChat({ show, handleClose, handleUpload, isFile }) {
  const dispatch = useDispatch()
  const detailUser = useSelector((state) => state.user.detailUser)
  const detailRoom = useSelector((state) => state.room.detailRoom)
  const myLocation = useSelector((state) => state.user.detailUser.location)
  const handleShareLocation = () => {
    const data = {
      typeMessage: 'location',
      message: 'Share Location',
      idRoom: detailRoom.idRoom,
      location: myLocation,
    }
    swalConfirm('Location', 'Share location', 'question', async () => {
      try {
        const response = await dispatch(fetchSendMessage(data))
        const responseMsgId = await dispatch(
          fetchMessageById(response.data.data.id),
        )
        dispatch({ type: MESSAGE_PUSH, payload: responseMsgId.data.data })
        const dataEmit = {
          sendWhat: 2,
          room: detailRoom.idRoom,
          type: detailRoom.type,
          roomName: detailRoom.name,
          name: detailUser.name,
          email: detailUser.email,
          senderId: detailUser.id,
          message: 'Share location',
          messageId: response.data.data.id,
        }
        socketClient.emit('sendMessage', dataEmit)
        handleClose()
      } catch (err) {
        toastError(err.response.data.message)
        handleClose()
      }
    })
  }

  const handleUploadDocument = (e) => {
    const data = {
      document: e.target.files[0],
      type: 'doc',
    }
    handleUpload(data)
    handleClose()
  }

  const handleUploadImage = (e) => {
    const data = {
      document: e.target.files[0],
      type: 'image',
    }
    handleUpload(data)
    handleClose()
  }

  return (
    <Modal show={show} animation={false} centered onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-4'>
            <div className='card border-0 shadow bg-dark text-white cursor-pointer'>
              <div
                className='card-body text-center disabled'
                onClick={handleShareLocation}
              >
                <ImLocation2 />
              </div>
            </div>
          </div>
          <div className='col-4'>
            <div className='card border-0 shadow bg-dark text-white'>
              <div
                className={`card-body text-center btn-file${
                  isFile.type === 'image' ? '' : ' pointer-disabled'
                }`}
              >
                <BsFillImageFill />
                {!isFile.type || isFile.type === 'image' ? (
                  <input
                    type='file'
                    accept='image/*'
                    className='form-control'
                    onChange={(e) => handleUploadImage(e)}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className='col-4'>
            <div className='card border-0 shadow bg-dark text-white'>
              <div
                className={`card-body text-center btn-file${
                  isFile.type === 'doc' ? '' : ' pointer-disabled'
                }`}
              >
                <IoMdDocument />
                {!isFile.type || isFile.type === 'doc' ? (
                  <input
                    type='file'
                    accept='application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf'
                    onChange={(e) => handleUploadDocument(e)}
                    className='form-control'
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalInputChat
