import React, { useEffect, useState } from 'react'
import { geolocated } from 'react-geolocated'
import { DarkModeButton } from '../../components'
import {
  NavbarLanding,
  HeroSection,
  FooterLanding,
  JoinSection,
  FeaturesSection,
  AboutSection,
} from '../../domain/Landing'

function Landing(props) {
  const [scrollNav, setScrollNav] = useState(false)
  const changeNav = () => {
    if (window.scrollY >= 60) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, [])

  return (
    <>
      <NavbarLanding scrollNav={scrollNav} />
      <HeroSection />
      <FeaturesSection />
      <AboutSection />
      <JoinSection />
      <FooterLanding />
      <div className='dark-mode-toggle'>
        <DarkModeButton v2 />
      </div>
    </>
  )
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(Landing)
