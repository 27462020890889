import React from 'react'
import { Modal } from 'react-bootstrap'
import { FaTimes } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { Avatar } from '../../../components'
import filterTime from '../../../utils/filterTime'

function ModalListViewers({ show, handleClose }) {
  const viewers = useSelector((state) => state.story.viewers)

  return (
    <>
      <Modal
        show={show}
        animation={false}
        centered
        className='modal-secondbc'
        onHide={handleClose}
      >
        <Modal.Body>
          <div className='d-flex justify-content-between align-items-center'>
            <p className='mb-0'>List viewers</p>
            <button className='btn btn-sm btn-primary' onClick={handleClose}>
              <FaTimes />
            </button>
          </div>
          <div
            className='mt-4 pr-4'
            style={{ maxHeight: '400px', overflowY: 'scroll' }}
          >
            {viewers.map((viewer, i) => (
              <div
                className='d-flex justify-content-between align-items-center mb-3'
                key={i}
              >
                <div className='d-flex align-items-center'>
                  <Avatar size='36px' src={viewer.image} />
                  <span className='ml-2'>{viewer.name}</span>
                </div>
                <span>{filterTime(viewer.createdAt)}</span>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ModalListViewers
