import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Button, InputError } from '../../../components'
import { fetchUpdateRoom } from '../../../state/room/roomActions'
import { toastError, toastSuccess } from '../../../utils/toastUtils'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'

function ModalSettingGroup({ show, handleClose }) {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const detailRoom = useSelector((state) => state.room.detailRoom)
  const [data, setData] = useState({
    name: '',
    description: '',
  })

  const validationSchema = Yup.object({
    name: Yup.string().required(),
    description: Yup.string().max(255),
  })

  useEffect(() => {
    setData({
      name: detailRoom.name,
      description: detailRoom.description,
    })
  }, [detailRoom, setData])

  const handleUpdate = async (values) => {
    setIsLoading(true)
    try {
      await dispatch(fetchUpdateRoom(detailRoom.idRoom, values))
      toastSuccess('Update success')
      setIsLoading(false)
      handleClose()
    } catch (err) {
      toastError(err.response.data.message)
      setIsLoading(false)
    }
  }

  return (
    <Formik
      initialValues={data}
      validationSchema={validationSchema}
      onSubmit={handleUpdate}
      enableReinitialize={true}
    >
      <Modal show={show} animation={false} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Form>
            <div className='form-group'>
              <label htmlFor='name'>Name</label>
              <ErrorMessage name='name' component={InputError} />
              <Field name='name' id='name' className='form-control' />
            </div>
            <div className='form-group'>
              <label htmlFor='description'>Description</label>
              <ErrorMessage name='description' component={InputError} />
              <Field
                as='textarea'
                name='description'
                id='description'
                className='form-control'
              />
            </div>
            <div className='float-right mt-2'>
              <Button
                variant='secondary'
                className='mr-2'
                onClick={handleClose}
              >
                Close
              </Button>
              <Button type='submit' isPrimary isLoading={isLoading}>
                Update
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </Formik>
  )
}

export default ModalSettingGroup
