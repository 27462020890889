const {
  GET_DETAIL_USER,
  GET_ALL_USER,
  CLEAR_USER_STATE,
} = require('../actionTypes')

const initialState = {
  detailUser: {},
  allUser: [],
}

const userReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_DETAIL_USER:
      return {
        ...state,
        detailUser: action.payload,
      }
    case GET_ALL_USER:
      return {
        ...state,
        allUser: action.payload,
      }
    case CLEAR_USER_STATE:
      return {
        detailUser: {},
        allUser: [],
      }
    default:
      return state
  }
}

export default userReducers
