import React from 'react'
import { Link as LinkS } from 'react-scroll'
import { Link as LinkR } from 'react-router-dom'
import { ICLogo, ICLogoWhite } from '../../../assets'
import { Button } from '../../../components'
import './navbar-landing.scss'
import { FaBars, FaTimes } from 'react-icons/fa'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const NavbarLanding = ({ scrollNav }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const isDarkMode = useSelector((state) => state.global.darkMode)

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen)
  const closeMobileMenu = () => setIsMenuOpen(false)

  return (
    <>
      <nav
        className={`navbar navbar-bg sticky-top py-3${
          scrollNav ? ' shadow-sm active' : ''
        }`}
      >
        <div className='container-fluid'>
          <div
            className={`w-100 d-flex align-items-center justify-content-between nav-wrapper`}
          >
            <LinkR className='navbar-brand' to='/'>
              <img
                src={isDarkMode ? ICLogoWhite : ICLogo}
                width='35'
                height='35'
                alt='logo'
                loading='lazy'
              />
              <span className={`brand-text ml-2 ${scrollNav ? ' active' : ''}`}>
                Mangga Chat
              </span>
            </LinkR>
            <div className='nav-lists'>
              <LinkS
                to='hero-section'
                smooth={true}
                duration={800}
                spy={true}
                exact='true'
                offset={-30}
                activeClass='active'
                className='nav-item'
              >
                Home
              </LinkS>
              <LinkS
                to='features'
                smooth={true}
                duration={800}
                spy={true}
                exact='true'
                offset={-30}
                activeClass='active'
                className='nav-item'
              >
                Features
              </LinkS>
              <LinkS
                to='about-us'
                smooth={true}
                duration={800}
                spy={true}
                exact='true'
                offset={10}
                activeClass='active'
                className='nav-item'
              >
                About Us
              </LinkS>
              <LinkS
                to='join'
                smooth={true}
                duration={800}
                spy={true}
                exact='true'
                activeClass='active'
                className='nav-item'
              >
                Join
              </LinkS>
            </div>
            <button
              className='btn btn-outline-dark menu-close'
              onClick={toggleMenu}
            >
              {isMenuOpen ? <FaTimes /> : <FaBars />}
            </button>
            <div className='btn-wrapper align-items-center'>
              <Button
                isBtn
                type='link'
                href='/auth/signin'
                className='mr-3 btn-log'
              >
                Log In
              </Button>
              <Button
                isBtn
                type='link'
                href='/auth/signup'
                className='bg-lb text-white'
                hasShadow
              >
                Sign Up
              </Button>
            </div>
          </div>
          <div className={`navbar-mobile${isMenuOpen ? ' active' : ''}`}>
            <div className='d-flex flex-column h-100 align-items-center justify-content-center py-3'>
              <LinkS
                to='hero-section'
                smooth={true}
                duration={800}
                spy={true}
                exact='true'
                offset={-30}
                activeClass='active'
                className='mobile-nav-item'
                onClick={closeMobileMenu}
              >
                Home
              </LinkS>
              <LinkS
                to='features'
                smooth={true}
                duration={800}
                spy={true}
                exact='true'
                offset={-30}
                activeClass='active'
                className='mobile-nav-item'
                onClick={closeMobileMenu}
              >
                Features
              </LinkS>
              <LinkS
                to='join'
                smooth={true}
                duration={800}
                spy={true}
                exact='true'
                activeClass='active'
                className='mobile-nav-item'
                onClick={closeMobileMenu}
              >
                Join
              </LinkS>
              <LinkS
                to='about-us'
                smooth={true}
                duration={800}
                spy={true}
                exact='true'
                offset={10}
                activeClass='active'
                className='mobile-nav-item'
                onClick={closeMobileMenu}
              >
                About Us
              </LinkS>
              <LinkR
                to='/auth/signin'
                onClick={closeMobileMenu}
                className='mobile-nav-item'
              >
                Log In
              </LinkR>
              <LinkR
                to='/auth/signup'
                onClick={closeMobileMenu}
                className='mobile-nav-item'
              >
                Sign Up
              </LinkR>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default NavbarLanding
