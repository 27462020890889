import React from 'react'
import './story.scss'
import { Avatar } from '../../../components'
import { useSelector } from 'react-redux'

function Story({
  dataStory,
  myStory,
  showStory,
  addStory,
  isLoading,
  targetId,
}) {
  const filterName = (name) => {
    return name.split('').length > 10 ? name.substr(0, 10) + '...' : name
  }
  const detailUser = useSelector((state) => state.user.detailUser)

  if (myStory) {
    return (
      <div className='mr-2 story' onClick={() => showStory(dataStory.idUser)}>
        <Avatar
          size='54px'
          isLoading={isLoading && targetId === detailUser.id}
          hasShadow
          src={dataStory.imageUser}
        />
        <span className='story-name mt-2'>Me</span>
      </div>
    )
  }

  if (addStory) {
    return (
      <div className='mr-2 story' onClick={() => showStory(dataStory.idUser)}>
        <Avatar size='54px' hasShadow src={dataStory.imageUser} />
        <span className='story-name mt-2'>Add</span>
      </div>
    )
  }

  return (
    <div className='mr-2 story' onClick={() => showStory(dataStory.idUser)}>
      <Avatar
        size='54px'
        src={dataStory.imageUser}
        isLoading={isLoading && targetId === dataStory.idUser}
        hasShadow
        border
      />
      <span className='story-name mt-2'>{filterName(dataStory.userName)}</span>
    </div>
  )
}

export default Story
