import store from '../state/store'

const { getState } = store

const filterFriend = (room) => {
  if (room.type === 1) {
    if (room.idSender === getState().user.detailUser.id) {
      const res = getState().friend.myFriend.filter(
        (item) => item.idFriend === room.idReceiver,
      )[0]
      return res || { iamge: '', friendName: '' }
    } else {
      const reselse = getState().friend.myFriend.filter(
        (item) => item.idFriend === room.idSender,
      )[0]
      return reselse || { iamge: '', friendName: '' }
    }
  } else {
    return 'NAH'
  }
}

export default filterFriend
