import React from 'react'
import Skeleton from 'react-loading-skeleton'

const StorySkeleton = () => {
  return (
    <>
      <div className='mr-2 mb-2'>
        <Skeleton circle={true} duration={2} height={60} width={60} />
      </div>
      <div className='mr-2 mb-2'>
        <Skeleton circle={true} duration={2} height={60} width={60} />
      </div>
      <div className='mr-2 mb-2'>
        <Skeleton circle={true} duration={2} height={60} width={60} />
      </div>
      <div className='mr-2 mb-2'>
        <Skeleton circle={true} duration={2} height={60} width={60} />
      </div>
      <div className='mr-2 mb-2'>
        <Skeleton circle={true} duration={2} height={60} width={60} />
      </div>
    </>
  )
}

export default StorySkeleton
