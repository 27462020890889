import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FaEnvelope, FaLock, FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { toastError, toastSuccess } from '../../../utils/toastUtils'
import { Button, InputError } from '../../../components'
import '../auth.scss'
import { fetchSignIn } from '../../../state/auth/authActions'
import { fetchDetailUser } from '../../../state/user/userActions'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

function SignIn() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isEyeOpen, setIsEyeOpen] = useState(false)

  const initialValues = {
    email: '',
    password: '',
  }

  const validationSchema = Yup.object({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  })
  const handleSignin = async (values) => {
    try {
      setIsLoading(true)
      const responseSignIn = await dispatch(fetchSignIn(values))
      await dispatch(fetchDetailUser(responseSignIn.data.data.idUser))
      setIsLoading(false)
      toastSuccess('Login success')
      history.push('/home')
    } catch (err) {
      values.password = ''
      toastError(err.response.data.message)
      setIsLoading(false)
    }
  }
  return (
    <>
      <h3 className='auth-title'>Sign In</h3>
      <p className='auth-desc'>Hi, Welcome back!</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSignin}
      >
        <Form>
          <ErrorMessage name='email' component={InputError} />
          <div className='form-group position-relative'>
            <div className='icon-auth-left'>
              <FaEnvelope />
            </div>
            <Field
              type='email'
              name='email'
              placeholder='Email'
              className='form-control auth-input'
            />
          </div>
          <ErrorMessage name='password' component={InputError} />
          <div className='form-group position-relative'>
            <div className='icon-auth-left'>
              <FaLock />
            </div>
            <div
              className='icon-auth-right'
              onClick={() => setIsEyeOpen(!isEyeOpen)}
            >
              {isEyeOpen ? <FaRegEyeSlash /> : <FaRegEye />}
            </div>
            <Field
              type={isEyeOpen ? 'text' : 'password'}
              name='password'
              placeholder='Password'
              className='form-control auth-input'
            />
          </div>

          <div className='clearfix'></div>
          <Button
            type='submit'
            isPrimary
            isLoading={isLoading}
            isBlock
            hasShadow
            className='btn-auth mt-4'
          >
            Sign In
          </Button>
        </Form>
      </Formik>
      <p className='mt-3 text-center mb-2 or-auth'>
        No have account ? <Link to='/auth/signup'>Sign up here</Link>
      </p>
      <div className='text-center or-auth'>
        <Link to='/auth/forgot-password'>Forgot password ?</Link>
      </div>
    </>
  )
}

export default SignIn
