import authReducers from './auth/authReducers'
import { combineReducers } from 'redux'
import userReducers from './user/userReducers'
import roomReducers from './room/roomReducers'
import friendReducers from './friend/friendReducers'
import messageReducers from './message/messageReducers'
import storyReducers from './story/storyReducers'
import globalReducers from './global/globalReducers'
import callReducers from './call/callReducers'

const rootReducer = combineReducers({
  auth: authReducers,
  user: userReducers,
  room: roomReducers,
  friend: friendReducers,
  message: messageReducers,
  story: storyReducers,
  global: globalReducers,
  call: callReducers,
})

export default rootReducer
