import storyApi from '../../apis/storyApi'
import {
  CLEAR_STORY_STATE,
  GET_DETAIL_STORY,
  GET_FRIEND_STORY,
  GET_MY_STORY,
  GET_VIEWERS_STORY,
} from '../actionTypes'

export const getFriendStory = (data) => {
  return {
    type: GET_FRIEND_STORY,
    payload: data,
  }
}

export const getViewersStory = (data) => {
  return {
    type: GET_VIEWERS_STORY,
    payload: data,
  }
}

export const getDetailStory = (data) => {
  return {
    type: GET_DETAIL_STORY,
    payload: data,
  }
}

export const clearStoryState = () => {
  return {
    type: CLEAR_STORY_STATE,
  }
}

export const fetchFriendStory = () => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      storyApi
        .getFriendStory()
        .then((response) => {
          resolve(response)
          dispatch(getFriendStory(response.data.data))
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchDetailStory = (id, page, myStory) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      storyApi
        .getDetailStory(id, page)
        .then((response) => {
          resolve(response)
          if (myStory) {
            dispatch({ type: GET_MY_STORY, payload: response.data })
          } else {
            dispatch(getDetailStory(response.data))
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchViewersStory = (id) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      storyApi
        .getViewers(id)
        .then((response) => {
          resolve(response)
          dispatch(getViewersStory(response.data.data))
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchAddStory = (data) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      storyApi
        .addStory(data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchUpdateViewers = (id) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      storyApi
        .updateViewers(id)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchDeleteStory = (id) => {
  return new Promise((resolve, reject) => {
    storyApi
      .deleteStory(id)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
