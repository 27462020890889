import React, { useState } from 'react'
import { toastError, toastSuccess } from '../../../utils/toastUtils'
import { Link, useHistory } from 'react-router-dom'
import { Button, InputError } from '../../../components'
import {
  FaEnvelope,
  FaUserAlt,
  FaLock,
  FaRegEye,
  FaRegEyeSlash,
} from 'react-icons/fa'
import '../auth.scss'
import { useDispatch } from 'react-redux'
import { fetchSignUp } from '../../../state/auth/authActions'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'

function SignUp() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [isEyeOpen, setIsEyeOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const initialValues = {
    name: '',
    email: '',
    password: '',
  }

  const validationSchame = Yup.object({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    password: Yup.string().min(6).required(),
  })

  const handleSubmit = async (values) => {
    setIsLoading(true)
    try {
      await dispatch(fetchSignUp(values))
      toastSuccess(
        'Sign up success. Please check your email to activate account.',
      )
      history.push('/auth/signin')
      setIsLoading(false)
    } catch (err) {
      console.log(err.response)
      toastError(err.response.data.message)
      values.password = ''
      setIsLoading(false)
    }
  }

  return (
    <>
      <h3 className='auth-title'>Sign Up</h3>
      <p className='auth-desc'>Let’s create your account!</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchame}
        onSubmit={handleSubmit}
      >
        <Form>
          <ErrorMessage name='name' component={InputError} />
          <div className='form-group position-relative'>
            <div className='icon-auth-left'>
              <FaUserAlt />
            </div>
            <Field
              name='name'
              placeholder='Name'
              className='form-control auth-input'
            />
          </div>
          <ErrorMessage name='email' component={InputError} />
          <div className='form-group position-relative'>
            <div className='icon-auth-left'>
              <FaEnvelope />
            </div>
            <Field
              name='email'
              type='email'
              placeholder='Email'
              className='form-control auth-input'
            />
          </div>
          <ErrorMessage name='password' component={InputError} />
          <div className='form-group position-relative'>
            <div className='icon-auth-left'>
              <FaLock />
            </div>
            <div
              className='icon-auth-right'
              onClick={() => setIsEyeOpen(!isEyeOpen)}
            >
              {isEyeOpen ? <FaRegEyeSlash /> : <FaRegEye />}
            </div>
            <Field
              name='password'
              type={isEyeOpen ? 'text' : 'password'}
              placeholder='Password'
              className='form-control auth-input'
            />
          </div>
          <Button
            type='submit'
            isPrimary
            isLoading={isLoading}
            isBlock
            hasShadow
            className='btn-auth mt-4'
          >
            Sign Up
          </Button>
        </Form>
      </Formik>
      <p className='mt-3 text-center mb-2 or-auth'>
        Already have account ? <Link to='/auth/signin'>Sign in here</Link>
      </p>
    </>
  )
}

export default SignUp
