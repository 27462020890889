import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Button, InputError } from '../../../components'
import { useDispatch } from 'react-redux'
import { fetchAddPublicRoom } from '../../../state/room/roomActions'
import { toastSuccess, toastError } from '../../../utils/toastUtils'
import socketClient from '../../../utils/socket'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'

function ModalGroup({ show, handleClose }) {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const handleAddGroup = async (values) => {
    setIsLoading(true)
    try {
      const response = await dispatch(fetchAddPublicRoom(values.name))
      socketClient.emit('joinSingleRoom', response.data.data.idRoom)
      toastSuccess('Group created successfully')
      setIsLoading(false)
      handleClose()
    } catch (err) {
      toastError(err.response.data.message)
      setIsLoading(false)
    }
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('Group name required'),
  })

  return (
    <Formik
      initialValues={{ name: '' }}
      validationSchema={validationSchema}
      onSubmit={handleAddGroup}
    >
      <Modal show={show} animation={false} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Group</Modal.Title>
        </Modal.Header>
        <Form>
          <div className='modal-body'>
            <div className='form-group'>
              <ErrorMessage name='name' component={InputError} />
              <Field
                name='name'
                type='text'
                placeholder='Group Name'
                className='form-control'
              />
            </div>
          </div>
          <div className='modal-footer'>
            <Button variant='secondary' onClick={handleClose}>
              Close
            </Button>
            <Button type='submit' isPrimary isLoading={isLoading}>
              Add
            </Button>
          </div>
        </Form>
      </Modal>
    </Formik>
  )
}

export default ModalGroup
