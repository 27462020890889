import React, { useEffect, useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { ContactCard } from '../../../components'
import {
  FaUserFriends,
  FaUserPlus,
  FaUserClock,
  FaUserTimes,
  FaSearch,
} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchDetailRoom,
  fetchMemberRoom,
  getPrivateRoom,
} from '../../../state/room/roomActions'
import { fetchDetailMessage } from '../../../state/message/messageActions'
import { Link } from 'react-router-dom'
import {
  fetchAccFriend,
  fetchDeleteFriend,
  fetchRejectFriend,
} from '../../../state/friend/friendActions'
import { toastError, toastSuccess } from '../../../utils/toastUtils'
import { swalConfirm } from '../../../utils/swal'
import socketClient from '../../../utils/socket'
import { fetchFriendStory } from '../../../state/story/storyActions'

function Friends() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getPrivateRoom())
  }, [dispatch])
  const [isLoadingRoom, setIsLoadingRoom] = useState(false)
  const [idRoom, setIdRoom] = useState(null)
  const handleDetail = async (id) => {
    setIdRoom(id)
    setIsLoadingRoom(true)
    await dispatch(fetchDetailMessage(id))
    await dispatch(fetchDetailRoom(id))
    await dispatch(fetchMemberRoom(id))
    setIsLoadingRoom(false)
  }
  const { privateRoom } = useSelector((state) => state.room)
  const detailUser = useSelector((state) => state.user.detailUser)
  const {
    friendRequest,
    friendRequestPending,
    friendRequestRejected,
    isLoading,
  } = useSelector((state) => state.friend)

  const handleRejectFriend = (id) => {
    swalConfirm('Friends', 'Decline friend requests', 'question', async () => {
      try {
        await dispatch(fetchRejectFriend(id))
        socketClient.emit('rejectFriend', {
          sender: detailUser.id,
          nameSender: detailUser.name,
          receiver: id,
        })
        toastSuccess('Friend request denied')
      } catch (err) {
        toastError(err.response.data.message)
      }
    })
  }

  const handleAccFriend = (id) => {
    swalConfirm('Friends', 'Accept friend requests', 'question', async () => {
      try {
        const response = await dispatch(fetchAccFriend(id))
        socketClient.emit('accFriend', {
          sender: detailUser.id,
          nameSender: detailUser.name,
          receiver: id,
          idRoom: response.data.data.idRoom,
        })
        await dispatch(fetchFriendStory())
        toastSuccess('Friend request accepted')
      } catch (err) {
        toastError(err.response.data.message)
      }
    })
  }

  const handleDeleteFriend = (id) => {
    swalConfirm('Friends', 'Delete rejected friend', 'question', async () => {
      try {
        await dispatch(fetchDeleteFriend(id))
        socketClient.emit('deleteFriend', {
          sender: detailUser.id,
          nameSender: detailUser.name,
          receiver: id,
        })
        toastSuccess('Friend deleted')
      } catch (err) {
        toastError(err.response.data.message)
      }
    })
  }

  return (
    <div className='mt-4'>
      <Tabs
        defaultActiveKey='friends'
        transition={false}
        id='uncontrolled-tab-example'
      >
        <Tab eventKey='friends' className='mt-4' title={<FaUserFriends />}>
          <p>Friend List</p>
          {privateRoom.length === 0 ? (
            <div className='d-flex align-items-center flex-column justify-content-center'>
              <h5 className='mt-2 mb-3'>No Friends Yet</h5>
              <Link className='btn btn-sm btn-success shadow' to='/home/users'>
                Find Friend <FaSearch />
              </Link>
            </div>
          ) : (
            privateRoom.map((room, id) => (
              <ContactCard
                unfriendButton
                room={room}
                key={id}
                isLoading={isLoadingRoom}
                targetId={idRoom}
                showDetail={handleDetail}
              />
            ))
          )}
        </Tab>

        <Tab eventKey='request' className='mt-4' title={<FaUserPlus />}>
          <p>List Request</p>
          {friendRequest.length === 0 ? (
            <h5 className='mt-5 text-center'>No Friend Request Yet</h5>
          ) : (
            friendRequest.map((friend, id) => (
              <ContactCard
                room={friend}
                type='friend-request'
                isLoading={isLoading}
                handleAccFriend={handleAccFriend}
                handleRejectFriend={handleRejectFriend}
                key={id}
              />
            ))
          )}
        </Tab>

        <Tab eventKey='pending' className='mt-4' title={<FaUserClock />}>
          <p>List Pending</p>
          {friendRequestPending.length === 0 ? (
            <h5 className='mt-5 text-center'>No Pending Request Yet</h5>
          ) : (
            friendRequestPending.map((friend, id) => (
              <ContactCard
                room={friend}
                type='friend-request-pending'
                key={id}
              />
            ))
          )}
        </Tab>

        <Tab eventKey='rejected' className='mt-4' title={<FaUserTimes />}>
          <p>List Rejected</p>
          {friendRequestRejected.length === 0 ? (
            <h5 className='mt-5 text-center'>No Rejected Request Yet</h5>
          ) : (
            friendRequestRejected.map((friend, id) => (
              <ContactCard
                room={friend}
                handleDeleteFriend={handleDeleteFriend}
                isLoading={isLoading}
                type='friend-request-rejected'
                key={id}
              />
            ))
          )}
        </Tab>
      </Tabs>
    </div>
  )
}

export default Friends
