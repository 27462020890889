import React, { useEffect, useRef, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import ModalAdd from '../ModalInputChat'
import { MdSend } from 'react-icons/md'
import { FaSmile, FaPlus, FaTimes, FaFire } from 'react-icons/fa'
import './chat-input.scss'
import { toastError } from '../../../utils/toastUtils'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchDocumentMessage,
  fetchImageMessage,
  fetchMessageById,
  fetchSendMessage,
} from '../../../state/message/messageActions'
import socketClient from '../../../utils/socket'
import Picker from 'emoji-picker-react'
import { MESSAGE_PUSH } from '../../../state/actionTypes'

function ChatInput() {
  const dispatch = useDispatch()
  const node = useRef()
  const inputRef = useRef()
  const [message, setMessage] = useState('')
  const [file, setFile] = useState({ document: null, type: null })
  const [showEmoji, setShowEmoji] = useState(false)
  const [showSticker, setShowSticker] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [dataTyping, setDataTyping] = useState({
    message: '',
    notif: '',
    profilePict: '',
  })
  const detailUser = useSelector((state) => state.user.detailUser)
  const detailRoom = useSelector((state) => state.room.detailRoom)
  const stickers = useSelector((state) => state.global.stickers)

  const onEmojiClick = (e, emojiObj) => {
    setMessage(`${message}${emojiObj.emoji}`)
  }

  const toggleEmoji = () => {
    if (showSticker) setShowSticker(false)
    setShowEmoji(!showEmoji)
  }

  const toggleSticker = () => {
    if (showEmoji) setShowEmoji(false)
    setShowSticker(!showSticker)
  }

  const handleClose = () => setShowModal(false)

  const handleShow = () => setShowModal(true)

  const handleTyping = (isClear) => {
    socketClient.emit('typing', {
      sendWhat: 1,
      room: detailRoom.idRoom,
      type: detailRoom.type,
      roomName: detailRoom.roomName,
      name: detailUser.name,
      email: detailUser.email,
      senderId: detailUser.id,
      message: isClear ? '' : message,
      photoProfile: detailUser.image,
    })
  }

  const handleSendTextMessage = async () => {
    setIsLoading(true)
    try {
      const data = {
        message,
        typeMessage: 'message',
        idRoom: detailRoom.idRoom,
      }
      if (file.type === 'doc') {
        const response = await dispatch(fetchSendMessage(sendDocument()))
        await pushMessage(response.data.data.id)
        handleTyping(true)
        setFile({ document: null, type: null })
        socketForMessage(4, response.data.data.id)
        setIsLoading(false)
        await dispatch(fetchDocumentMessage(detailRoom.idRoom))
      } else if (file.type === 'image') {
        const response = await dispatch(fetchSendMessage(sendImage()))
        await pushMessage(response.data.data.id)
        handleTyping(true)
        setFile({ document: null, type: null })
        socketForMessage(3, response.data.data.id)
        setIsLoading(false)
        await dispatch(fetchImageMessage(detailRoom.idRoom))
      } else {
        const response = await dispatch(fetchSendMessage(data))
        await pushMessage(response.data.data.id)
        handleTyping(true)
        setFile({ document: null, type: null })
        socketForMessage(1, response.data.data.id)
        setIsLoading(false)
      }
      setMessage('')
    } catch (err) {
      toastError(err.response)
      setIsLoading(false)
    }
  }

  const handleSendSticker = async (data) => {
    const sticker = {
      title: data.title,
      gif: data.url,
    }
    const dataSticker = {
      typeMessage: 'sticker',
      idRoom: detailRoom.idRoom,
      message: 'Send stickers',
      sticker: JSON.stringify(sticker),
    }
    const response = await dispatch(fetchSendMessage(dataSticker))
    await pushMessage(response.data.data.id)
    setFile({ document: null, type: null })
    socketForMessage(5, response.data.data.id)
    setShowSticker(false)
  }

  const handleKeyPress = (e) => {
    if (e.code === 'Enter' && e.shiftKey === false) {
      e.preventDefault()
      handleSendTextMessage()
    }
  }

  const handleUpload = (data) => {
    setFile(data)
  }

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      return
    }
    setShowEmoji(false)
    setShowSticker(false)
  }

  const sendDocument = () => {
    const formData = new FormData()
    if (file.document) {
      if (file.document.size > 2097152) {
        return toastError('Max file size 2MB')
      }
    }
    formData.append('file', file.document)
    formData.append('message', message || 'Send document')
    formData.append('typeMessage', file.type)
    formData.append('idRoom', detailRoom.idRoom)
    return formData
  }

  const sendImage = () => {
    const formData = new FormData()
    if (file.document) {
      if (file.document.size > 2097152) {
        return toastError('Max file size 2MB')
      }
    }
    formData.append('file', file.document)
    formData.append('message', message || 'Send image')
    formData.append('typeMessage', file.type)
    formData.append('idRoom', detailRoom.idRoom)
    return formData
  }

  const pushMessage = async (id) => {
    const response = await dispatch(fetchMessageById(id))
    dispatch({ type: MESSAGE_PUSH, payload: response.data.data })
  }

  useEffect(() => {
    socketClient.on('typingNotif', (data) => {
      if (data.room === detailRoom.idRoom) {
        setDataTyping({
          message: data.message,
          notif: data.notif,
          profilePict: data.image,
        })
      }
    })
  }, [detailRoom])

  const socketForMessage = (type, messageId) => {
    const data = {
      sendWhat: type,
      room: detailRoom.idRoom,
      type: detailRoom.type,
      roomName: detailRoom.name,
      name: detailUser.name,
      email: detailUser.email,
      senderId: detailUser.id,
      message: message,
      messageId,
    }
    if (type === 3) {
      data.message = message ? message : 'Send image'
    } else if (type === 4) {
      data.message = message ? message : 'Send document'
    }
    socketClient.emit('sendMessage', data)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  return (
    <>
      <div className='form-group mb-0' ref={node}>
        {dataTyping.message.length > 0 ? (
          <span className='font-12 ml-2'>{dataTyping.notif}</span>
        ) : (
          ''
        )}

        <div className='emoji-wrapper'>
          {showEmoji && <Picker onEmojiClick={onEmojiClick} />}
        </div>
        {showSticker ? (
          <div className='sticker-wrapper'>
            <span className='font-weight-bold'>via GIPHY</span>
            <div className='sticker-list'>
              {stickers.map((sticker, i) => (
                <img
                  onClick={() =>
                    handleSendSticker({
                      url: sticker.images.preview_gif.url,
                      title: sticker.bitly_gif_url,
                    })
                  }
                  key={i}
                  src={sticker.images.preview_gif.url}
                  alt={sticker.title}
                  className='sticker-item'
                />
              ))}
            </div>
          </div>
        ) : (
          ''
        )}
        {file.document && (
          <>
            <span className='badge badge-primary'>
              1 {file.type === 'doc' ? 'document' : 'image'} selected
            </span>
            <span
              className='cursor-pointer ml-1'
              onClick={() => setFile({ document: null, type: null })}
            >
              <FaTimes />
            </span>
          </>
        )}
        <div className='chat-input-icon'>
          <span className='icon-plus' onClick={handleShow}>
            <FaPlus />
          </span>
          <span onClick={toggleEmoji} className='icon-emoji'>
            <FaSmile />
          </span>
          <span onClick={toggleSticker} className='icon-sticker'>
            <FaFire />
          </span>
          <span className='icon-send'>
            {isLoading ? (
              <div className='spinner-border spinner-border-sm' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            ) : (
              <span onClick={handleSendTextMessage}>
                <MdSend />
              </span>
            )}
          </span>
          <TextareaAutosize
            className='chat-input form-control font-14'
            onKeyUp={() => handleTyping(false)}
            onKeyPress={(e) => handleKeyPress(e)}
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            maxRows='5'
            ref={inputRef}
            placeholder='Type your message...'
          />
        </div>
      </div>
      <ModalAdd
        show={showModal}
        handleUpload={handleUpload}
        isFile={file}
        handleClose={handleClose}
      />
    </>
  )
}

export default ChatInput
