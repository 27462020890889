import React, { useEffect } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'
import SignIn from './SignIn'
import SignUp from './SignUp'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import { useSelector } from 'react-redux'
import { DarkModeButton } from '../../components'

function Auth() {
  const history = useHistory()
  let { path } = useRouteMatch()
  const token = useSelector((state) => state.auth.token)
  useEffect(() => {
    if (token) {
      history.push('/home')
    }
  }, [token, history])
  return (
    <>
      <div className='bg-auth'>
        <div className='container'>
          <div className='row '>
            <div className='col-lg-5 col-md-9 col-12 mx-auto'>
              <h2
                className='text-center mb-3 cursor-pointer font-weight-bold'
                onClick={() => history.push('/')}
              >
                Mangga Chat
              </h2>
              <div className='card card-auth border-0'>
                <div className='card-body'>
                  <Switch>
                    <Route exact path={`${path}/reset-password`}>
                      <ResetPassword />
                    </Route>
                    <Route exact path={`${path}/forgot-password`}>
                      <ForgotPassword />
                    </Route>
                    <Route exact path={`${path}/signin`}>
                      <SignIn />
                    </Route>
                    <Route exact path={`${path}/signup`}>
                      <SignUp />
                    </Route>
                    <Route exact path={`${path}`}>
                      <Redirect to='/auth/signin' />
                    </Route>
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='dark-mode-toggle'>
        <DarkModeButton v2 />
      </div>
    </>
  )
}

export default Auth
