import React, { useEffect, useState } from 'react'
import { Modal, ProgressBar } from 'react-bootstrap'
import {
  FaArrowLeft,
  FaArrowRight,
  FaTimes,
  FaTrash,
  FaEye,
} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import { Avatar, LazyImage } from '../../../components'
import {
  fetchDeleteStory,
  fetchDetailStory,
  fetchUpdateViewers,
  fetchViewersStory,
} from '../../../state/story/storyActions'
import filterTime from '../../../utils/filterTime'
import { toastError, toastSuccess } from '../../../utils/toastUtils'
import './modal-story.scss'
import { swalConfirm } from '../../../utils/swal'
import socketClient from '../../../utils/socket'

function ModalStory({
  show,
  handleClose,
  isPause,
  setIsPause,
  handleShowViewers,
}) {
  const dispatch = useDispatch()
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const detailUser = useSelector((state) => state.user.detailUser)
  const [progress, setProgress] = useState(0)
  const { currentStory, viewers } = useSelector((state) => state.story)

  useEffect(() => {
    if (detailUser.id !== currentStory.data.idUser) {
      dispatch(fetchUpdateViewers(currentStory.data.id))
    }
  }, [detailUser, dispatch, currentStory])

  useEffect(() => {
    const getStory = async (page) => {
      setIsPause(true)
      setIsLoading(true)
      const response = await dispatch(
        fetchDetailStory(currentStory.data.idUser, page),
      )
      await dispatch(fetchViewersStory(response.data.data.id))
      setIsPause(false)
      setIsLoading(false)
    }
    if (show) {
      if (!isPause) {
        if (progress < 100) {
          const interval = setInterval(() => {
            setProgress((prev) => prev + 2)
          }, 200)
          return () => clearInterval(interval)
        } else {
          if (currentStory.total_pages > currentStory.current_page) {
            getStory(currentStory._links.next)
            setProgress(0)
          } else {
            setIsPause(false)
            setProgress(0)
            handleClose()
          }
        }
      }
    }
  }, [handleClose, isPause, progress, setIsPause, show, currentStory, dispatch])

  const togglePause = () => {
    setIsPause(!isPause)
  }

  const closeModal = () => {
    setIsPause(false)
    setProgress(0)
    handleClose()
  }

  const handleNext = async () => {
    setIsPause(true)
    setIsLoading(true)
    const response = await dispatch(
      fetchDetailStory(currentStory.data.idUser, currentStory._links.next),
    )
    await dispatch(fetchViewersStory(response.data.data.id))
    setIsPause(false)
    setProgress(0)
    setIsLoading(false)
  }
  const handlePrev = async () => {
    setIsPause(true)
    setIsLoading(true)
    const response = await dispatch(
      fetchDetailStory(currentStory.data.idUser, currentStory._links.prev),
    )
    await dispatch(fetchViewersStory(response.data.data.id))
    setIsPause(false)
    setProgress(0)
    setIsLoading(false)
  }
  const handleDeleteStory = () => {
    setIsPause(true)
    swalConfirm('Story', 'Delete story', 'question', async () => {
      setLoadingDelete(true)
      try {
        await fetchDeleteStory(currentStory.data.id)
        const response = await dispatch(
          fetchDetailStory(detailUser.id, 1, true),
        )
        if (response.data.total === 0) {
          socketClient.emit('transfer-signal-story', {
            idUser: detailUser.id,
            type: 'delete',
          })
        }
        toastSuccess('Delete success')
        setIsPause(false)
        setProgress(0)
        handleClose()
        setLoadingDelete(false)
      } catch (err) {
        toastError(err.response.data.message)
        setLoadingDelete(false)
      }
    })
  }

  return (
    <>
      <Modal show={show} animation={false} centered onHide={closeModal}>
        <Modal.Body className='p-0 position-relative'>
          <div className='wrapper-progress'>
            <ProgressBar now={progress} variant='dark' />
          </div>
          <span className='close-story' onClick={closeModal}>
            <FaTimes />
          </span>
          {currentStory.data.idUser === detailUser.id && (
            <span className='trash-story' onClick={handleDeleteStory}>
              {loadingDelete ? (
                <Spinner animation='border' size='sm' />
              ) : (
                <FaTrash />
              )}
            </span>
          )}
          <div className='container mt-5 mb-4'>
            <div className='position-relative'>
              <div className='wrapper-progress'>
                <ProgressBar
                  now={
                    (currentStory.current_page * 100) / currentStory.total_pages
                  }
                />
              </div>
              <span className='total-story badge badge-primary'>
                {currentStory.current_page}/{currentStory.total}
              </span>
            </div>
            <div className='mt-3 d-flex justify-content-between align-items-center'>
              <div className='d-flex align-items-center w-100'>
                <Avatar size='45px' src={currentStory.data.imageUser} />
                <div className='d-flex justify-content-between align-items-center w-100'>
                  <div className='ml-2 d-flex flex-column justify-content-between'>
                    <span>{currentStory.data.userName}</span>
                    <span className='font-12'>
                      {filterTime(currentStory.data.createdAt)}
                    </span>
                  </div>
                  {!isLoading && (
                    <>
                      {currentStory.data.idUser === detailUser.id && (
                        <p
                          className='text-center cursor-pointer mb-0'
                          onClick={handleShowViewers}
                        >
                          <FaEye /> {viewers.length}
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div>
                {currentStory._links.prev && (
                  <span className='cursor-pointer mr-2' onClick={handlePrev}>
                    <FaArrowLeft />
                  </span>
                )}
                {currentStory._links.next && (
                  <span className='cursor-pointer' onClick={handleNext}>
                    <FaArrowRight />
                  </span>
                )}
              </div>
            </div>
            <div
              className='content-story mt-3 mb-3 d-flex flex-column align-items-center justify-content-center'
              onDoubleClick={togglePause}
            >
              {isLoading ? (
                <Spinner animation='border' />
              ) : (
                <LazyImage
                  src={currentStory.data.image}
                  className='img-fluid'
                />
              )}
            </div>
            {!isLoading && (
              <>
                <p className='text-center'>{currentStory.data.text}</p>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ModalStory
