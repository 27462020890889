const {
  GET_MY_FRIEND,
  CLEAR_FRIEND_STATE,
  ADD_FRIEND,
} = require('../actionTypes')

const initialState = {
  myFriend: [],
  friendRequest: [],
  friendRequestPending: [],
  friendRequestRejected: [],
}

const friendReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_FRIEND:
      return {
        ...state,
        myFriend: action.payload.friends,
        friendRequestPending: action.payload.friends.filter((item) => {
          return (
            item.status === 0 &&
            item.idSender === action.payload.idMe &&
            item.idUser === action.payload.idMe
          )
        }),
        friendRequest: action.payload.friends.filter((item) => {
          return (
            item.status === 0 &&
            item.idUser === action.payload.idMe &&
            item.idSender !== action.payload.idMe
          )
        }),
        friendRequestRejected: action.payload.friends.filter((item) => {
          return (
            item.status === 2 &&
            item.idUser === action.payload.idMe &&
            item.idSender === action.payload.idMe
          )
        }),
      }
    case ADD_FRIEND:
      return {
        ...state,
        friendRequestPending: action.payload,
      }
    case CLEAR_FRIEND_STATE:
      return {
        myFriend: [],
        friendRequest: [],
        friendRequestPending: [],
        friendRequestRejected: [],
      }
    default:
      return state
  }
}

export default friendReducers
