import React, { useEffect, useState } from 'react'
import './contact-card.scss'
import Avatar from '../Avatar'
import Button from '../Button'
import {
  FaEllipsisV,
  FaTimes,
  FaBell,
  FaBellSlash,
  FaCheck,
  FaUserPlus,
  FaUserTimes,
} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { toastError, toastSuccess } from '../../utils/toastUtils'
import { fetchChangeNotification } from '../../state/room/roomActions'
import { fetchUnfriend } from '../../state/friend/friendActions'
import { Spinner } from 'react-bootstrap'
import socketClient from '../../utils/socket'
import filterFriend from '../../utils/filterFriend'
import { swalConfirm } from '../../utils/swal'
import {
  CLEAR_DETAIL_MESSAGE,
  CLEAR_DETAIL_ROOM,
} from '../../state/actionTypes'

function ContactCard({
  unfriendButton,
  room,
  showDetail,
  type,
  handleAddFriend,
  handleAccFriend,
  handleDeleteFriend,
  handleRejectFriend,
  isLoading,
  targetId,
}) {
  const dispatch = useDispatch()
  const [setting, setSetting] = useState(false)
  const [dataTyping, setDataTyping] = useState('')
  const [roomLoading, setRoomLoading] = useState(false)
  const detailUser = useSelector((state) => state.user.detailUser)
  const detailRoom = useSelector((state) => state.room.detailRoom)

  const handleChangeNotif = async (id) => {
    setRoomLoading(true)
    try {
      await dispatch(fetchChangeNotification(id))
      setRoomLoading(false)
    } catch (err) {
      toastError(err.response.data.message)
      setRoomLoading(false)
    }
  }

  const handleUnfriend = (idRoom) => {
    console.log(idRoom)
    swalConfirm(
      'Unfriend',
      'Are you sure want to unfriend',
      'question',
      async () => {
        try {
          dispatch({ type: CLEAR_DETAIL_MESSAGE })
          dispatch({ type: CLEAR_DETAIL_ROOM })
          await dispatch(fetchUnfriend(idRoom))
          const dataSocket = {
            idRoom,
            nameSender: detailUser.name,
            idSender: detailUser.id,
          }
          socketClient.emit('ts-unfriend', dataSocket)
          setSetting(false)
          toastSuccess('Unfriend success')
        } catch (err) {
          toastError(err.response.data.message)
        }
      },
    )
  }

  useEffect(() => {
    socketClient.on('typingNotif', (data) => {
      if (data.room === room.idRoom) {
        setDataTyping({
          id: data.room,
          message: data.message,
          notif: data.notif,
        })
      }
    })
  }, [room])

  const filterMessage = (message, deletedAt) => {
    if (deletedAt) {
      return '(Deleted)'
    } else {
      return message.length > 30 ? message.substr(0, 30) + '...' : message
    }
  }

  const filterName = (name) => {
    return name.split('').length > 10 ? name.substr(0, 10) + '...' : name
  }

  const filterTime = (val) => {
    const date = new Date(val)
    const minute = date.getMinutes()
    const hours = date.getHours()
    const result = `${hours < 10 ? '0' + hours : hours}:${
      minute < 10 ? '0' + minute : minute
    }`
    return result
  }

  if (type === 'friend-request') {
    return (
      <div className='card mb-3 contact-card'>
        <div className='card-body'>
          <div className='main-contact'>
            <div className='left-contact'>
              <Avatar src={room.image} rounded='left' size='48px' />
              <div className='d-flex flex-column ml-3'>
                <span className='contact-title'>{room.friendName}</span>
                <span className='contact-description'>
                  Send a friend request
                </span>
              </div>
            </div>
            <div className='d-flex align-items-center'>
              <Button
                className='mr-2'
                variant='danger'
                isSmall
                isLoading={isLoading}
                hasShadow
                title='Reject'
                onClick={() => handleRejectFriend(room.idFriend)}
              >
                <FaTimes />
              </Button>
              <Button
                isSmall
                variant='success'
                isLoading={isLoading}
                hasShadow
                title='Acc'
                onClick={() => handleAccFriend(room.idFriend)}
              >
                <FaCheck />
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (type === 'friend-request-pending') {
    return (
      <div className='card mb-3 contact-card'>
        <div className='card-body'>
          <div className='main-contact'>
            <div className='left-contact'>
              <Avatar src={room.image} rounded='left' size='48px' />
              <div className='d-flex flex-column ml-3'>
                <span className='contact-title'>{room.friendName}</span>
                <span className='contact-description'>
                  Request has been sent
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (type === 'friend-request-rejected') {
    return (
      <div className='card mb-3 contact-card'>
        <div className='card-body'>
          <div className='main-contact'>
            <div className='left-contact'>
              <Avatar rounded='left' size='48px' />
              <div className='d-flex flex-column ml-3'>
                <span className='contact-title'>{room.friendName}</span>
                <span className='contact-description'>
                  Friend request rejected
                </span>
              </div>
            </div>
            <div className='d-flex align-items-center'>
              <Button
                isSmall
                variant='danger'
                isLoading={isLoading}
                onClick={() => handleDeleteFriend(room.idFriend)}
                title='Delete'
                hasShadow
              >
                <FaTimes />
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (type === 'list-users') {
    return (
      <div className='card mb-3 contact-card'>
        <div className='card-body'>
          <div className='main-contact'>
            <div className='left-contact'>
              <Avatar rounded='left' src={room.image} size='48px' />
              <div className='d-flex flex-column ml-3'>
                <span className='contact-title'>{room.name}</span>
              </div>
            </div>
            <div className='d-flex align-items-center'>
              <Button
                isSmall
                variant='info'
                isLoading={isLoading}
                hasShadow
                title='Add'
                onClick={() => handleAddFriend(room.id)}
              >
                <FaUserPlus />
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div
      className={`card mb-3 contact-card ${
        detailRoom.idRoom === room.idRoom ? ' active' : ''
      }`}
    >
      <div className='card-body'>
        <div className='main-contact'>
          <div className='left-contact'>
            {room.type === 2 ? (
              <Avatar rounded='left' text={room.name} size='48px' />
            ) : (
              <Avatar
                rounded='left'
                src={filterFriend(room).image}
                size='48px'
              />
            )}
            {!setting ? (
              <div
                className='d-flex flex-column cursor-pointer'
                onClick={() => showDetail(room.idRoom)}
              >
                <span className='contact-title ml-3'>
                  {room.type === 2
                    ? filterName(room.name)
                    : filterName(filterFriend(room).friendName)}
                </span>
                <span className='contact-description ml-3'>
                  {isLoading && targetId === room.idRoom ? (
                    <>
                      <Spinner className='mr-2' animation='grow' size='sm' />
                      <Spinner className='mr-2' animation='grow' size='sm' />
                      <Spinner animation='grow' size='sm' />
                    </>
                  ) : dataTyping.id === room.idRoom &&
                    dataTyping.message.length > 0 ? (
                    dataTyping.notif
                  ) : room.type === 2 ? (
                    room.sender === detailUser.id ? (
                      'Me: '
                    ) : (
                      `${room.userName}: `
                    )
                  ) : (
                    ''
                  )}
                  {dataTyping.id === room.idRoom &&
                  dataTyping.message.length > 0
                    ? ''
                    : isLoading && targetId === room.idRoom
                    ? ''
                    : filterMessage(room.message, room.deletedAt)}
                </span>
              </div>
            ) : (
              <>
                <Button
                  hasShadow
                  title='Notification'
                  isLoading={roomLoading}
                  isSmall
                  className='ml-3'
                  variant={room.notification ? 'success' : 'dark'}
                  onClick={() => handleChangeNotif(room.idRoom)}
                >
                  {room.notification ? <FaBell /> : <FaBellSlash />}
                </Button>
                {unfriendButton && (
                  <Button
                    hasShadow
                    title='Unfriend'
                    isLoading={roomLoading}
                    isSmall
                    className='ml-2'
                    variant='danger'
                    onClick={() => handleUnfriend(room.idRoom)}
                  >
                    <FaUserTimes />
                  </Button>
                )}
              </>
            )}
          </div>
          <div className='d-flex align-items-center'>
            {!setting ? (
              <div className='right-contact mr-2'>
                <span className='clock'>{filterTime(room.createdAt)}</span>
                {room.unread > 0 ? (
                  <span className='badge badge-primary badge-pill'>
                    {room.unread}
                  </span>
                ) : (
                  ''
                )}
              </div>
            ) : null}
            <span
              className='cursor-pointer'
              onClick={() => setSetting(!setting)}
            >
              {!setting ? (
                <FaEllipsisV className='icon-bar-v' />
              ) : (
                <FaTimes className='icon-bar-v' />
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactCard
