import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { ICMenu } from '../../../assets'
import {
  FaCog,
  FaUserAlt,
  FaUserPlus,
  FaPowerOff,
  FaQuestionCircle,
} from 'react-icons/fa'
import './navbar-left.scss'
import { DarkModeButton } from '../../../components'
import { logoutAction } from '../../../state/auth/authActions'
import { useDispatch } from 'react-redux'
import { swalConfirm } from '../../../utils/swal'
import { toastSuccess } from '../../../utils/toastUtils'

function NavbarLeft(props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const handleLogout = () => {
    swalConfirm(
      'Logout',
      'Are you sure you want to log out',
      'question',
      async () => {
        await dispatch(logoutAction())
        history.push('/auth/signin')
        toastSuccess('Logout Success')
      },
    )
  }

  return (
    <div className='sidebar-title mt-4 mb-4 d-flex justify-content-between align-items-center'>
      <h5 className='mb-0 color-lb' v-if='!newTab'>
        Mangga Chat
      </h5>
      {props.newTab ? (
        <div className='bg-lb rounded-pill px-4' v-if='newTab'>
          <Link to='/users' className='btn mr-2'>
            Image Group
          </Link>
          <Link to='/private' className='btn mr-2'>
            Image Lock
          </Link>
          <button className='btn' v-b-modal="'modal-room'">
            Image Chanel
          </button>
        </div>
      ) : null}
      <div className='btn-group drop-nav'>
        <button
          type='button'
          className='btn p-0 no-focus'
          data-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded='false'
        >
          <img src={ICMenu} alt='menu' className='mr-2 cursor-pointer' />
        </button>
        <div
          className='dropdown-menu py-3 border-0 dropdown-menu-right'
          style={{ minWidth: '200px' }}
        >
          <button
            onClick={() => history.push('/home/setting')}
            className='dropdown-item no-focus'
            type='button'
          >
            <FaCog />
            <span className='pl-3'>Setting</span>
          </button>
          <Link
            to='/home/friends'
            className='dropdown-item no-focus'
            type='button'
          >
            <FaUserAlt />
            <span className='pl-3'>Friends</span>
          </Link>
          <Link
            to='/home/users'
            className='dropdown-item no-focus'
            type='button'
          >
            <FaUserPlus />
            <span className='pl-3'>Find Users</span>
          </Link>
          <Link to='/users' className='dropdown-item no-focus' type='button'>
            <FaQuestionCircle />
            <span className='pl-3'>Help</span>
          </Link>
          <button
            className='dropdown-item no-focus'
            type='button'
            onClick={() => handleLogout()}
          >
            <FaPowerOff />
            <span className='pl-3'>Logout</span>
          </button>
          <div className='dropdown-item no-focus cursor-pointer'>
            <DarkModeButton />
          </div>
        </div>
      </div>
      {/* <ModalRoom /> */}
    </div>
  )
}

export default NavbarLeft
