import AuthApi from '../../apis/authApi'
import {
  CHANGE_FIRST_LOADING,
  LOGOUT_USER,
  SIGNIN_SUCCESS,
} from '../actionTypes'
import { clearCallState } from '../call/callActions'
import { clearFriendState } from '../friend/friendActions'
import { clearMessageState } from '../message/messageActions'
import { clearRoomState } from '../room/roomActions'
import { clearStoryState } from '../story/storyActions'
import { clearUserState } from '../user/userActions'

export const signinSuccess = (data) => {
  return {
    type: SIGNIN_SUCCESS,
    payload: data,
  }
}

export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  }
}

export const changeFirstLoading = (data) => {
  return {
    type: CHANGE_FIRST_LOADING,
    payload: data,
  }
}

export const fetchSignIn = (data) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      AuthApi.signIn(data)
        .then((response) => {
          resolve(response)
          dispatch(signinSuccess(response.data.data.token))
          localStorage.setItem('token', response.data.data.token)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchSignUp = (data) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      AuthApi.signUp(data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchForgotPassword = (data) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      AuthApi.forgotPassword(data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchActivateAccount = (data) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      AuthApi.activateAccount(data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchVerifyResetPassword = (data) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      AuthApi.verifyResetPassword(data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchResetPassword = (data) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      AuthApi.resetPassword(data)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const logoutAction = () => {
  return function (dispatch) {
    localStorage.removeItem('token')
    dispatch(clearFriendState())
    dispatch(clearMessageState())
    dispatch(clearRoomState())
    dispatch(clearStoryState())
    dispatch(clearUserState())
    dispatch(clearCallState())
    dispatch(logoutUser())
  }
}
