import React from 'react'
import { ILEmptyChat1 } from '../../../assets'
import { LazyImage } from '../../../components'
import './empty-chat.scss'

function ChatEmpty() {
  return (
    <div
      className="col-lg-8 col-md-7 empty-chat no-message content-message max-vh-100 min-vh-100"
    >
      <div className="text-center">
        <LazyImage src={ILEmptyChat1} isSvg className="img-fluid" />
        <p className="mb-1 mt-2 font-weight-bold">Hallo Cep Guna</p>
        <p>Please select a chat to start messaging</p>
      </div>
    </div>
  )
}

export default ChatEmpty
