import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { LazyImage } from '../../components'

function ZoomImage({ show, handleClose, image }) {
  return (
    <Modal show={show} animation={false} onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div>
          <LazyImage src={image} alt='zoom-img' className='img-fluid' />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ZoomImage
