import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CHANGE_THEME } from '../../state/actionTypes'
import { FaMoon, FaSun } from 'react-icons/fa'

function DarkModeButton({ className, showLabel, v2 }) {
  const dispatch = useDispatch()
  const isDarkMode = useSelector((state) => state.global.darkMode)

  const handleChangeMode = () => {
    if (!isDarkMode) {
      document.body.classList.add('dark-mode')
    } else {
      document.body.classList.remove('dark-mode')
    }
    dispatch({ type: CHANGE_THEME, payload: !isDarkMode })
    localStorage.setItem('DARK_MODE', !isDarkMode)
  }

  if (v2) {
    return (
      <>
        <button className='btn darkmode-v2' onClick={handleChangeMode}>
          {isDarkMode ? (
            <span className='text-white'>
              <FaMoon />
            </span>
          ) : (
            <FaSun />
          )}
        </button>
      </>
    )
  }

  return (
    <>
      <div className={`custom-control custom-switch ${className}`}>
        <input
          type='checkbox'
          checked={isDarkMode}
          onChange={handleChangeMode}
          className='custom-control-input'
          id='customSwitch1'
        />
        <label className='custom-control-label' htmlFor='customSwitch1'>
          {showLabel && 'Dark mode'}
        </label>
      </div>
    </>
  )
}

export default DarkModeButton
