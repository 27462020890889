import FriendApi from '../../apis/friendApi'
import { CLEAR_FRIEND_STATE, GET_MY_FRIEND } from '../actionTypes'
import { fetchMyRoom } from '../room/roomActions'
import { fetchAllUser } from '../user/userActions'

export const getMyFriend = (data, id) => {
  return {
    type: GET_MY_FRIEND,
    payload: { friends: data, idMe: id },
  }
}

export const clearFriendState = () => {
  return {
    type: CLEAR_FRIEND_STATE,
  }
}

export const fetchMyFriend = () => {
  return function (dispatch, getState) {
    const id = getState().user.detailUser.id
    return new Promise((resolve, reject) => {
      FriendApi.getMyFriends()
        .then((response) => {
          resolve(response)
          dispatch(getMyFriend(response.data.data, id))
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchAddFriend = (id) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      FriendApi.addFriend({ idUser: id })
        .then((response) => {
          resolve(response)
          dispatch(fetchAllUser())
          dispatch(fetchMyFriend())
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchUnfriend = (id) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      FriendApi.unfriend(id)
        .then((response) => {
          resolve(response)
          dispatch(fetchMyFriend())
          dispatch(fetchMyRoom())
          dispatch(fetchAllUser())
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchRejectFriend = (id) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      FriendApi.rejectFriend({ idUser: id })
        .then((response) => {
          resolve(response)
          dispatch(fetchMyFriend())
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchAccFriend = (id) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      FriendApi.accFriend({ idUser: id })
        .then((response) => {
          resolve(response)
          dispatch(fetchMyFriend())
          dispatch(fetchMyRoom())
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchDeleteFriend = (id) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      FriendApi.deleteFriend(id)
        .then((response) => {
          resolve(response)
          dispatch(fetchMyFriend())
          dispatch(fetchAllUser())
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
