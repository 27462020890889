import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FaEnvelope } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { toastError, toastSuccess } from '../../../utils/toastUtils'
import { Button, InputError } from '../../../components'
import '../auth.scss'
import { fetchForgotPassword } from '../../../state/auth/authActions'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

function ForgotPassword() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const initialValues = {
    email: '',
  }
  const validationSchema = Yup.object({
    email: Yup.string().email().required(),
  })

  const handleSubmit = async (values) => {
    setIsLoading(true)
    try {
      await dispatch(fetchForgotPassword({ email: values.email }))
      toastSuccess('A link to change the password has been sent to your email')
      history.push('/auth/signin')
      setIsLoading(false)
    } catch (err) {
      toastError(err.response.data.message)
      values.email = ''
      setIsLoading(false)
    }
  }

  return (
    <>
      <h2 className='auth-title'>Forgot Password</h2>
      <p className='auth-desc'>You’ll get messages soon on your e-mail</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <ErrorMessage name='email' component={InputError} />
          <div className='form-group position-relative'>
            <div className='icon-auth-left'>
              <FaEnvelope />
            </div>
            <Field
              name='email'
              type='email'
              placeholder='Email'
              className='form-control auth-input'
            />
          </div>

          <div className='clearfix'></div>
          <Button
            type='submit'
            isPrimary
            isLoading={isLoading}
            isBlock
            hasShadow
            className='btn-auth mt-4'
          >
            Send
          </Button>
        </Form>
      </Formik>
      <p className='mt-3 text-center mb-2 or-auth'>
        Already have account ? <Link to='/auth/signin'>Sign in here</Link>
      </p>
    </>
  )
}

export default ForgotPassword
