const {
  GET_DETAIL_MESSAGE,
  CLEAR_DETAIL_MESSAGE,
  CLEAR_MESSAGE_STATE,
  MESSAGE_PUSH,
  GET_IMAGE_MESSAGE,
  GET_DOCUMENT_MESSAGE,
} = require('../actionTypes')

const initialState = {
  detailMessage: [],
  imageMessage: [],
  documentMessage: [],
}

const messageReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_DETAIL_MESSAGE:
      return {
        ...state,
        detailMessage: action.payload,
      }
    case GET_IMAGE_MESSAGE:
      return {
        ...state,
        imageMessage: action.payload,
      }
    case GET_DOCUMENT_MESSAGE:
      return {
        ...state,
        documentMessage: action.payload,
      }
    case MESSAGE_PUSH:
      return {
        ...state,
        detailMessage: [action.payload, ...state.detailMessage],
      }
    case CLEAR_DETAIL_MESSAGE:
      return {
        ...state,
        detailMessage: [],
      }
    case CLEAR_MESSAGE_STATE:
      return {
        detailMessage: [],
        imageMessage: [],
        documentMessage: [],
      }
    default:
      return state
  }
}

export default messageReducers
