import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Avatar, Button, InputError } from '../../../components'
import { useDispatch, useSelector } from 'react-redux'
import { updateProfileUser } from '../../../state/user/userActions'
import { toastError, toastSuccess } from '../../../utils/toastUtils'
import { Formik, Form as FormFormik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

function ModalUpdateProfile({ show, handleClose }) {
  const { detailUser } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const [image, setImage] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const [data, setData] = useState({
    name: '',
    username: '',
    phoneNumber: '',
    bio: '',
  })

  useEffect(() => {
    setData({
      name: detailUser.name,
      username: detailUser.username,
      phoneNumber: detailUser.phoneNumber,
      bio: detailUser.bio,
    })
  }, [detailUser, setData])
  const validationSchema = Yup.object({
    name: Yup.string().required(),
    username: Yup.string().required(),
    phoneNumber: Yup.string()
      .required('phone number required')
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(10),
    bio: Yup.string().required(),
  })

  const handleUpdate = async (values) => {
    setIsLoading(true)
    const formData = new FormData()
    if (image) {
      if (image.size > 2097152) {
        setIsLoading(false)
        return toastError('Max file size 2MB')
      }
    }
    formData.append('image', image)

    formData.append('name', values.name)
    formData.append('username', values.username)
    formData.append('phoneNumber', values.phoneNumber)
    formData.append('bio', values.bio)
    try {
      await dispatch(updateProfileUser(detailUser.id, formData))
      setIsLoading(false)
      handleClose()
      toastSuccess('Data successfully updated')
    } catch (err) {
      toastError(err.response.data.message)
      setIsLoading(false)
    }
  }

  return (
    <Formik
      initialValues={data}
      validationSchema={validationSchema}
      onSubmit={handleUpdate}
      enableReinitialize={true}
    >
      <Modal size='lg' animation={false} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Profile</Modal.Title>
        </Modal.Header>
        <FormFormik>
          <div className='modal-body'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor='name'>Name</label>
                  <ErrorMessage name='name' component={InputError} />
                  <Field
                    name='name'
                    id='name'
                    className='form-control no-shadow'
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='username'>Username</label>
                  <ErrorMessage name='username' component={InputError} />
                  <Field
                    name='username'
                    id='username'
                    className='form-control no-shadow'
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='phoneNumber'>Phone Number</label>
                  <ErrorMessage name='phoneNumber' component={InputError} />
                  <Field
                    name='phoneNumber'
                    id='phoneNumber'
                    className='form-control no-shadow'
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor='bio'>Bio</label>
                  <ErrorMessage name='bio' component={InputError} />
                  <Field
                    name='bio'
                    id='bio'
                    className='form-control no-shadow'
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='image'>Image</label>
                  <ErrorMessage name='image' component={InputError} />
                  <span>{image ? '1 file selected' : null}</span>
                  <input
                    name='image'
                    type='file'
                    id='image'
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='name'>Old Image</label>
                  <div>
                    <Avatar src={detailUser.image} rounded='sm' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <Button variant='secondary' onClick={handleClose}>
              Close
            </Button>
            <Button type='submit' isPrimary isLoading={isLoading}>
              Update
            </Button>
          </div>
        </FormFormik>
      </Modal>
    </Formik>
  )
}

export default ModalUpdateProfile
