import RoomApi from '../../apis/roomApi'
import {
  GET_DETAIL_ROOM,
  GET_MEMBER_IN_ROOM,
  GET_MY_ROOM,
  GET_GROUP_ROOM,
  GET_PRIVATE_ROOM,
  CLEAR_ROOM_STATE,
  GET_MY_ROOM_STATIC,
} from '../actionTypes'

export const getMyRoom = (data) => {
  return {
    type: GET_MY_ROOM,
    payload: data,
  }
}

export const getMyRoomStatic = (data) => {
  return {
    type: GET_MY_ROOM_STATIC,
    payload: data,
  }
}

export const getDetailRoom = (data) => {
  return {
    type: GET_DETAIL_ROOM,
    payload: data,
  }
}

export const getMemberRoom = (data) => {
  return {
    type: GET_MEMBER_IN_ROOM,
    payload: data,
  }
}

export const getGroupRoom = () => {
  return function (dispatch, getState) {
    const groupRoom = getState().room.myRoom.filter((item) => item.type === 2)
    dispatch({
      type: GET_GROUP_ROOM,
      payload: groupRoom,
    })
  }
}

export const getPrivateRoom = () => {
  return function (dispatch, getState) {
    const privateRoom = getState().room.myRoom.filter((item) => item.type === 1)
    dispatch({
      type: GET_PRIVATE_ROOM,
      payload: privateRoom,
    })
  }
}

export const clearRoomState = () => {
  return {
    type: CLEAR_ROOM_STATE,
  }
}

export const fetchMyRoom = () => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      RoomApi.getMyRoom()
        .then((response) => {
          resolve(response)
          dispatch(getMyRoom(response.data.data))
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchOnceMyRoom = () => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      RoomApi.getMyRoom()
        .then((response) => {
          resolve(response)
          dispatch(getMyRoomStatic(response.data.data))
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchDetailRoom = (id) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      RoomApi.getDetailRoom(id)
        .then((response) => {
          resolve(response)
          dispatch(getDetailRoom(response.data.data))
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchMemberRoom = (id) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      RoomApi.getMemberRoom(id)
        .then((response) => {
          resolve(response)
          dispatch(getMemberRoom(response.data.data))
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchAddPublicRoom = (data) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      RoomApi.addPublicRoom({ name: data })
        .then((response) => {
          resolve(response)
          dispatch(fetchMyRoom())
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchChangeNotification = (id) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      RoomApi.changeNotification(id)
        .then((response) => {
          resolve(response)
          dispatch(fetchMyRoom())
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchLastSeenRoom = (id) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      RoomApi.updateLastSeen(id)
        .then((response) => {
          resolve(response)
          dispatch(fetchMyRoom())
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchStatusInvite = (id) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      RoomApi.changeStatusInvite(id)
        .then((response) => {
          resolve(response)
          dispatch(fetchDetailRoom(id))
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchKickUser = (data) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      RoomApi.kickUser(data)
        .then((response) => {
          resolve(response)
          dispatch(fetchMemberRoom(data.idRoom))
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchLeaveGroup = (data) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      RoomApi.leaveRoom(data)
        .then((response) => {
          resolve(response)
          dispatch(fetchMyRoom())
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchInviteUser = (data) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      RoomApi.inviteUser(data)
        .then((response) => {
          resolve(response)
          dispatch(fetchDetailRoom(data.idRoom))
          dispatch(fetchMemberRoom(data.idRoom))
          dispatch(fetchMyRoom())
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export const fetchUpdateRoom = (id, data) => {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      RoomApi.updateRoom(id, data)
        .then((response) => {
          resolve(response)
          dispatch(fetchDetailRoom(id))
          dispatch(fetchMyRoom())
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
