import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-scroll'
import { ILHeroLight, ILHeroDark } from '../../../assets'
import { LazyImage } from '../../../components'
import RellaxWrapper from 'react-rellax-wrapper'
import './hero-section.scss'

const HeroSection = () => {
  const isDarkMode = useSelector((state) => state.global.darkMode)
  return (
    <>
      <div id='hero-section'>
        <div className='container'>
          <div className='hero-section'>
            <RellaxWrapper speed={3}>
              <h1 className='hero-title mb-0'>
                Chat with your friends & family with <br />
                Mangga Chat
              </h1>
            </RellaxWrapper>
            <RellaxWrapper speed={2}>
              <p className='hero-desc'>
                Send messages, share stories, share locations and call people
                closest to you or your friends
              </p>
            </RellaxWrapper>

            <RellaxWrapper speed={1}>
              <div className='mb-5 d-flex'>
                <Link
                  className='btn text-white bg-lb btn-hero mr-3 d-flex align-items-center justify-content-center shadow mt-4 font-weight-bold'
                  to='features'
                  smooth={true}
                  duration={800}
                  spy={true}
                  exact='true'
                  offset={-30}
                  activeClass='active'
                >
                  Learn More
                </Link>
                <Link
                  className='btn btn-secondary btn-hero d-flex align-items-center justify-content-center shadow mt-4 font-weight-bold'
                  to='features'
                  smooth={true}
                  duration={800}
                  spy={true}
                  exact='true'
                  offset={-30}
                  activeClass='active'
                >
                  Join Now
                </Link>
              </div>
            </RellaxWrapper>
            <LazyImage
              src={isDarkMode ? ILHeroDark : ILHeroLight}
              alt='manggachat-preview'
              className='img-fluid hero-img'
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroSection
