import React from 'react'
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import propTypes from 'prop-types'
import L from 'leaflet'
import './map.scss'
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;
function MapItem({height, location}) {
  if(location) {
    const newPosition = JSON.parse(location)
    const position = [newPosition.lat, newPosition.lng]
    return (
      <div>
        <MapContainer center={position} style={{height: height || 300}} zoom={13} scrollWheelZoom={false}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
          </MapContainer>
      </div>
    )
  }
  const position = [51.505, -0.09]
  return (
    <div>
      <MapContainer center={position} style={{height: height || 300}} zoom={13} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
        </MapContainer>
    </div>
  )
}

export default MapItem

MapItem.propTypes = {
  height: propTypes.number
}