import React, { useEffect } from 'react'
import './feature-item.scss'
import AOS from 'aos'
import 'aos/dist/aos.css'
const FeatureItem = ({ title, description, children }) => {
  useEffect(() => {
    AOS.init()
  }, [])
  return (
    <div className='col-md-4 mb-4' data-aos='fade-up'>
      <div className='card feature-card'>
        <div className='card-body'>
          <div className='d-flex align-items-center mb-3'>
            <span className='feature-icon'>{children}</span>
            <h5 className='feature-title'>{title}</h5>
          </div>
          <p className='feature-desc'>{description}</p>
        </div>
      </div>
    </div>
  )
}

export default FeatureItem
